import { useAuthContext } from '@/components/Hooks/Auth/AuthHook';
import AdminMenu from '@/components/Navigation/AdminMenu';
import AnonMenu from '@/components/Navigation/AnonMenu';

export default function Root() {
  const { authed } = useAuthContext();
  return (
    <>
      {authed ? <AdminMenu /> : <AnonMenu />}
    </>
  );
}