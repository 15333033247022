import ClientsSelect from '@/components/Misc/ClientsMultiSelect';

export const enum SnackbarSeverity {
    INFO = "info",
    WARNING = "warning",
    SUCCESS = "success",
    ERROR = "error"
}

export const enum BotScraperIds {
    MEIJER = 'meijer',
    DG = 'dg',
    IPC = 'ipc',
    VIQ = 'viq',
    KOHLS = 'kohls',
    WGFORECAST = 'wgforecast',
    WGSUNDAY = 'wgsun',
    VIQEMAIL = 'emailss',
    CPFR = 'bbycpfr',
    AVP = 'avp',
    ANDA = 'anda',
    POL_CUSTOM = 'polcustom',
    POL_APP = 'polapp',
    EDI = 'edi'
}

export type Campaign = {
    CampaignId: number
    Name: string
    Description: string
    StartDate: string
    EndDate: string
    FileType: string
    LastUpdated: string
};

export const enum BotLoginDto {
    USERNAME = 'username',
    PASSWORD = 'password',
    DOWNLOADPATH = 'downloadPath'
}

export type Portal = {
    PortalId: number
    Name: string
    Description: string
    Domain: string
};

export type CampCred = {
    CredId: number
    PortalId: number
    Username: string
    Pasword: string
    MFAType: string
    LastUpdated: string
    Status: boolean
};

export type Contact = {
    ContactId: number
    Email: string
    Name: string
    LastContacted: string
    Roles: string
};

export type Bot = {
    BotId: number
    Name: string
    Description: string
    PortalId: number
    RunApiEndpoint: string
};

export type BotConfig = {
    ConfigId: number
    BotId: number
    Name: string
    Description: string
    CronTab: string
    DestPath: string
    CredId: number
    Enabled: boolean
};

export type Credential = {
    CredId: number
    Username: string
    Password: string
    MFAType: string
    LastUpdated: string
    Status: boolean
};

export type VIQBackCandids = {
    VDSNumber: number
    ClientShortCode: string
};

export type Job = {
    ConfigId: number
    Name: string
    Description: string
    JobString: string
    CreateDate: string
    ModifiedDate: string
    ModifiedBy: string
    ModifiedReason: string
};

export type UpdateJobDto = {
    ConfigId: number
    Name: string
    Description: string
    JobString: string
    ModifiedReason: string
};

export type ConfigRunMetric = {
    ConfigId: number
    CurrentMessage: string
    LastRunStartTime: Date | null
    LastRunEndTime: Date | null
    WasSuccess: boolean
    Owner: string
};

// [
//     {
//       "name": "SetSight",
//       "id": "39360",
//       "user_id": "BAB18680"
//     },
//     {
//       "name": "Cargill Cocoa and Chocolate",
//       "id": "1138",
//       "user_id": "BAB90360"
//     },
// ]

export type POLVdsIds = {
    Name: string
    Id: string
    User_id: string
}

export type PolScrapeJob = {
    Name: string
    Id: string
    clientSelect: typeof ClientsSelect
}

export type Destination = {
    DestPath: string
    Name: string
    Description: string
}

export type Person = {
    ContactId: number,
    Email: string,
    Name: string,
    LastContacted: string,
    Roles: string
}

// { CsrfToken = csrfToken, FormAction = formAction, SessionCookie = sessionCookie, PfCookie = pfCookie }
export type WgRunBot = {
    Otp: string
    CsrfToken: string
    FormAction: string
    SessionCookie: string
    PfCookie: string
    SupplierIds: string | undefined
    DownloadPath: string | undefined
};

export type WgLoginResp = {
    CsrfToken: string
    FormAction: string
    SessionCookie: string
    PfCookie: string
}

export type ScraperProps = {
    botCode: string | undefined
    name: string
    description: string
    config: BotConfig | null
    setConfigState: any
    cred: Credential | null
    setCredState: any
    job: Job | null
    setJobState: any
}

export type AuthContextType = {
    authed: boolean
    // login: (dto: any) => Promise<unknown>
    // logout: () => Promise<unknown>
    setAuthed: any
};

export type LoginDto = {
    email: string
    password: string
};
