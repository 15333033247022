import React, { useContext, useEffect } from "react";

import { ss3Login, authorize } from "@/api/admin-api";
import { AuthContextType, LoginDto } from "@/constants";

export const AuthContext = React.createContext<AuthContextType>({authed: false, setAuthed: ()=>false});

export function useAuthContext() {
    const context = React.useContext(AuthContext)
    // If context is undefined, we know we used RadioGroupItem
    // outside of our provider so we can throw a more helpful
    // error!
    if (context === undefined) {
      throw Error('Auth must be used inside of a AuthProvider, ' + 'otherwise it will not function correctly.')
    }
  
    // Because of TypeScript's type narrowing, if we make it past
    // the error the compiler knows that context is always defined
    // at this point, so we don't need to do any conditional
    // checking on its values when we use this hook!
    return context
}

// export function useLogin() {
    
// }

export function useAuth() {
    // api/authorize
    const { authed, setAuthed } = useContext(AuthContext);

    return {
        authed,
        login(dto: LoginDto) {
            return new Promise((res: any, rej: any) => {
                ss3Login(dto).then(()=>{
                    setAuthed(true);
                    res();
                },()=>{
                    setAuthed(false)
                    rej()
                })

            });
        },
        logout() {
            return new Promise((res: any) => {
                setAuthed(false);
                res();
            });
        },
    };
}

type Props = {
	children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
    const [authed, setAuthed] = React.useState<boolean>(false);
    const [isLoad, setIsLoading] = React.useState<boolean>(false);
    // const auth = useAuth() as AuthContextType;

    useEffect(()=>{
        authorize().then((resp: any)=>{
            setAuthed(true)
        }).catch((err: any)=>{
            setAuthed(false)
        })
    }, [])
    
    return <AuthContext.Provider value={{authed, setAuthed}}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
    return React.useContext(AuthContext);
}
