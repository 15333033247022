import React, { useState } from 'react';
import { Navigate } from "react-router-dom";

import { useAuth } from '@/components/Hooks/Auth/AuthHook';

export default function Login() {

    const { login, authed } = useAuth();

    const [isLoginSuccess, setIsLoginSuccess] = useState<boolean>(true)

    async function handleLogin(e: React.SyntheticEvent) {
        e.preventDefault()
        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
          };
          const email = target.email.value; // typechecks!
          const password = target.password.value; // typechecks!

        login({email: email, password: password}).then(() => {
            setIsLoginSuccess(true)
        }, ()=> {
            setIsLoginSuccess(false)
        });
    }

    if (authed) {
        return (<Navigate to={'/overview'} replace />)
    } else {

        return (
            <section className="text-center text-lg-start">
                <div className={isLoginSuccess ? "card mb-3" : "card mb-3 invalid-form"}>
                    <div className="row g-0 d-flex align-items-center">
                        <div className="card-body py-5 px-md-5">
                            <form onSubmit={handleLogin} method='post'>
                                {/* Email input */}
                                <div className="form-outline mb-4">
                                    <input
                                        type="email"
                                        id="form2Example1"
                                        className="form-control"
                                        name='email'
                                    />
                                    <label className="form-label" htmlFor="form2Example1">
                                        Email address
                                    </label>
                                </div>
                                {/* Password input */}
                                <div className="form-outline mb-4">
                                    <input
                                        type="password"
                                        id="form2Example2"
                                        className="form-control"
                                        name='password'
                                    />
                                    <label className="form-label" htmlFor="form2Example2">
                                        Password
                                    </label>
                                </div>
                                {/* Submit button */}
                                <button type="submit" className="btn btn-primary btn-block mb-4">
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
