import { faNotdef } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { AlertColor } from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from "react";
import { useLoaderData, Link } from "react-router-dom";

import { getPortals } from '@/api/admin-api';
import Api, { getCampContacts, getCampaignPortals, getCampaign, deleteCampContact } from "@/api/campaign-api";
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Campaign, Contact, Portal, Credential, SnackbarSeverity, CampCred } from "@/constants";

export async function loader({ params }: any) {
    const campaign : Campaign = await getCampaign(params.campaignId);
    const campContacts : Array<Contact> = await getCampContacts(params.campaignId)
    let campPortals : Array<Portal> = await getCampaignPortals(params.campaignId);
    if (!Array.isArray(campPortals)) {
        const tmpCampPorts = campPortals;
        campPortals = []
        campPortals.push(tmpCampPorts)
    }
    const portals : Array<Portal> = await getPortals();
    const campCreds: Array<CampCred> = await Api.getCampCreds(params.campaignId);
    return { campaign, campContacts, campPortals, portals, campCreds };
}

export default function CampaignForm() {
  const { campaign, campContacts, campPortals, portals, campCreds }: any = useLoaderData();

    const [availPortals, setAvailPortals] = useState<Array<Portal>>(portals.filter(({PortalId: id1}: Portal) => !campPortals.some(({PortalId: id2}: Portal) => id2 === id1)));
    const [portalsBag, setPortalsBag] = useState<Array<Portal>>(campPortals);

    const [newContactForm, setNewContactForm] = useState<boolean>(false)
    const [editCamp, setEditCamp] = useState<boolean>(false)
    const [showAvailPortals, setShowAvailPortals] = useState<boolean>(false)

    // campaign
    const [name, setName] = useState<string>(campaign.Name)
    const [description, setDescription] = useState<string>(campaign.Description)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs(campaign.StartDate, 'MM/DD/YYYY H:mm:ss A'));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs(campaign.EndDate, 'MM/DD/YYYY H:mm:ss A'));
    const [fileType, setFileType] = useState<string>(campaign.FileType);

    // useEffect(() => {
    //     getCampPortCred(campaign.CampaignId, portal.PortalId);
    // }, []);

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };  

    function handleRemoveContact(e: any) {
        const contact: Contact  = campContacts.find((contact: Contact) => contact.ContactId === e.ContactId);
        if (window.confirm(`Are you sure you'd like to remove the user "${contact.Name}" with email "${contact.Email}" from the campaign?`)) {
            deleteCampContact(campaign.CampaignId, e.ContactId).then(() => {
                handleShowSnackBar(`Successfully deleted the contact.`, SnackbarSeverity.SUCCESS);
            }).catch(() => {
                handleShowSnackBar(`Failed to delete the contact.`, SnackbarSeverity.ERROR);
            })
        }
    }

    function handleResendCredRequest(contactId: number) {
        // console.log(`clicked resend cred request contactId ${contactId}`)
        handleShowSnackBar(`Resend cred request not yet implemented.`, SnackbarSeverity.WARNING);
    }

    function handleDeleteCred(credId: number) {
        if (window.confirm(`Are you sure you'd like to delete the cred?`)) {
            Api.deleteCred(credId).then(() => {
                handleShowSnackBar(`Success deleting cred.`, SnackbarSeverity.SUCCESS);
            }).catch(() => {
                handleShowSnackBar(`Failed to delete the cred.`, SnackbarSeverity.ERROR);
            })
        }
    }

    function handleAddNewContact(e: any) {
        e.preventDefault()
        const formJson = Object.fromEntries(new FormData(e.target).entries());
        if (window.confirm(`Are you sure you'd like to assign "${formJson.contactName}" and email "${formJson.contactEmail}"?`)) {
            Api.campaignCredInvite(campaign.CampaignId, formJson).then(() => {
                handleShowSnackBar(`Successfully added a new contact.`, SnackbarSeverity.SUCCESS);
            }).catch(() => {
                handleShowSnackBar(`Failed to added a new contact.`, SnackbarSeverity.ERROR);
            })
        }
    }

    function handleAddPortal(e: any) {
        const index = portalsBag.findIndex(x => x.PortalId == e.PortalId);
        index === -1 ? setPortalsBag(prev => [...prev, e]) : console.log("portal already exists")
        setAvailPortals(availPortals.filter(item => item.PortalId !== e.PortalId))
    }

    function handleRemovePortal(e: any) {
        setPortalsBag(portalsBag.filter((item: Portal) => item.PortalId !== e.PortalId))
        const index = availPortals.findIndex((x: Portal) => x.PortalId == e.PortalId);
        index === -1 ? setAvailPortals(prev => [...prev, e]) : console.log("portal already exists")
    }

    function handleSavePortals(e: any) {
        const portsToAdd = JSON.stringify(portalsBag.map(({PortalId}) => (PortalId)))
        const portsToRemove = JSON.stringify(availPortals.map(({PortalId}) => (PortalId)))
        Api.saveCampPorts(campaign.CampaignId, portsToAdd, portsToRemove).then(() => {
            console.log('saved ports')
            handleShowSnackBar(`Saved portals.`, SnackbarSeverity.SUCCESS);
        }).catch((err: any) => {
            console.error('failed to save ports')
            handleShowSnackBar(`Error saving portals: ${err.statusText}`, SnackbarSeverity.ERROR);
        })
    }

    function handleUpdateCamp(e: any) {
        e.preventDefault();
        const formJson = Object.fromEntries(new FormData(e.target).entries());
        formJson.StartDate = startDate.format('MM/DD/YYYY HH:mm:ss A');
        formJson.EndDate = endDate.format('MM/DD/YYYY HH:mm:ss A');
        formJson.CampaignId = campaign.CampaignId
        Api.putCampaign(campaign.CampaignId, formJson)
            .then((res: any) => {
                handleShowSnackBar(`Successfully updated the campaign.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Failed to update the campaign: ${error.statusText}`, SnackbarSeverity.ERROR);
        });
    }
  
    return (
        <div id="camp-container">
            <form onSubmit={handleUpdateCamp}>
                {editCamp ?
                    <div className="campaign-entry-header">
                        <div className='heading'>
                            <label htmlFor="name" className='text-label'>Name:</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                required={true}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <p className="subheading">Last updated: {campaign.LastUpdated}</p>
                    </div>
                : 
                    <div className="campaign-entry-header">
                        <h1 className="heading">{campaign.Name}</h1>
                        <p className="subheading">Last updated: {campaign.LastUpdated}</p>
                    </div>
                }
                    <fieldset className="form-group border p-3">
                        <legend>Details</legend>
                            <div className='text-input-label-group'>
                                <label htmlFor="description" className='text-label'>Description:</label>
                                {editCamp ?
                                    <input 
                                        type="text" 
                                        name="description" 
                                        id="description"
                                        required={true}
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                :
                                    <div className="campdesc">{campaign.Description}</div>
                                }
                            </div>
                    </fieldset>
                    
                    {/* timespan */}
                    <fieldset className="form-group border p-3">
                        <legend>Time Span</legend>
                        <div className="form-group" id="calendar-pickers">
                            <div className="datepicker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="start date of campaign" onChange={(value: any) => setStartDate(dayjs(value))} value={startDate} disabled={!editCamp} />
                                </LocalizationProvider>
                            </div>
                            <div className='datepicker'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="end date of campaign" onChange={(value: any) => setEndDate(dayjs(value))} value={endDate} disabled={!editCamp} />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="form-group border p-3" hidden>
                        <legend>File Type Selection</legend>
                        <div className='select-container'>
                            <select title='File Type' id='fileTypeSelect' name="fileType" className='form-select' onChange={e => setFileType(e.target.value)} value={fileType}>
                                <option value='None'>None</option>
                                <option value='CSV'>CSV</option>
                                <option value='PDF'>PDF</option>
                                <option value='XLSX'>XLSX</option>
                            </select>
                        </div>
                    </fieldset>

                    <button type="button" className='btn btn-secondary' onClick={() => setEditCamp(!editCamp)}>{editCamp ? '<- View' : 'Edit Campaign'}</button>
                    {editCamp ? <button type="submit" className='btn btn-primary'>Submit</button> : <></>}
                </form>
                
                <div>
                    <fieldset className="form-group border p-3">
                        <legend>Creds & Portals</legend>
                        {portalsBag.length > 0 ?
                        <div>
                            <h2>Campaign Portal Cred{portalsBag.length > 1 ? 's' : ''}:</h2>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Portal</TableCell>
                                                <TableCell align="right">Username</TableCell>
                                                <TableCell align="right">Password (encrypted)</TableCell>
                                                <TableCell align="right">MFA Type</TableCell>
                                                <TableCell align="right">Last Updated</TableCell>
                                                <TableCell align="right">Status</TableCell>
                                                <TableCell align="right">Edit Cred</TableCell>
                                                <TableCell align="right">Remove</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {portalsBag?.map((portal: Portal, i: number) => {
                                            const portalCred: Credential = campCreds.find((cred: CampCred) => portal.PortalId === cred.PortalId);
                                            if (portalCred)
                                                return (
                                                    <TableRow
                                                        key={i}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {portal.Name}
                                                        </TableCell>
                                                        <TableCell align="right">{portalCred.Username}</TableCell>
                                                        <TableCell align="right">{portalCred.Password}</TableCell>
                                                        <TableCell align="right">{portalCred.MFAType}</TableCell>
                                                        <TableCell align="right">{portalCred.LastUpdated}</TableCell>
                                                        <TableCell align="right">{portalCred.Status ? 'Good' : 'Bad'}</TableCell>
                                                        <TableCell align="right"><Link to={`/credentials/${portalCred.CredId}`}><span className='clickable'><EditIcon /></span></Link></TableCell>
                                                        <TableCell align="right"><span className='clickable' onClick={() => handleRemovePortal(portal)}><DeleteForeverIcon /></span></TableCell>
                                                    </TableRow>
                                                )
                                            else
                                            return (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {portal.Name}
                                                    </TableCell>
                                                    <TableCell colSpan={6} align="center"><FontAwesomeIcon icon={faNotdef} /></TableCell>
                                                    <TableCell align="right"><span className='clickable' onClick={() => handleRemovePortal(portal)}><DeleteForeverIcon /></span></TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        </div>
                        : campCreds.length == 1 && portalsBag.length == 0 ?
                        <div>
                            <h2>Campaign Misc Cred:</h2>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">Username</TableCell>
                                            <TableCell align="right">Password (encrypted)</TableCell>
                                            <TableCell align="right">MFA Type</TableCell>
                                            <TableCell align="right">Last Updated</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                            <TableCell align="right">Edit Cred</TableCell>
                                            <TableCell align="right">Remove</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {campCreds.length > 0 ? campCreds.map((cred: Credential, i: number) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {cred.Username}
                                            </TableCell>
                                            <TableCell align="right">{cred.Password}</TableCell>
                                            <TableCell align="right">{cred.MFAType}</TableCell>
                                            <TableCell align="right">{cred.LastUpdated}</TableCell>
                                            <TableCell align="right">{cred.Status ? 'Good' : 'Bad'}</TableCell>
                                            <TableCell align="right"><Link to={`/credentials/${cred.CredId}`}><span className='clickable'><EditIcon /></span></Link></TableCell>
                                            <TableCell align="right"><span className='clickable' onClick={(e: any) => {
                                                e.preventDefault();
                                                handleDeleteCred(cred.CredId);
                                            }}><DeleteForeverIcon /></span></TableCell>
                                        </TableRow>
                                    ))
                                    : 
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={7} align="center"><FontAwesomeIcon icon={faNotdef} /></TableCell>
                                        </TableRow>
                                    }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        :
                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Username</TableCell>
                                        <TableCell align="right">Password (encrypted)</TableCell>
                                        <TableCell align="right">MFA Type</TableCell>
                                        <TableCell align="right">Last Updated</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Edit Cred</TableCell>
                                        <TableCell align="right">Remove</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={7} align="center"><FontAwesomeIcon icon={faNotdef} /></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </div>
                        }
                    </fieldset>

                    <button className='btn btn-secondary' type="button" onClick={() => setShowAvailPortals(!showAvailPortals)}>{showAvailPortals ? '<-' : 'Add a Portal'}</button>
                    {showAvailPortals ?
                    <fieldset className="form-group border p-3">
                        <legend>Available Portal{availPortals.length > 1 ? 's' : ''}</legend>
                        <ul>
                            {availPortals.length > 0 ?
                                availPortals.map((portal: Portal, i: number) =>
                                    <li key={portal?.PortalId}><span className='clickable' title="add-portal" onClick={() => handleAddPortal(availPortals[i])}><AddIcon /></span>{portal?.Name}</li>
                                )
                                : <li>None</li>
                            }
                        </ul>
                    </fieldset>
                    : <></> }
                    <button type="button" className='btn btn-primary' onClick={handleSavePortals}>Save Portal Changes</button>
                </div>
            <fieldset className="form-group border p-3">
                <legend>People</legend>
                <ul>
                    {campContacts.length > 0 ?
                        campContacts.map((contact: Contact, i: number) => 
                            <li id="contact" key={contact?.ContactId}>
                                <span className='clickable' onClick={() => handleRemoveContact(campContacts[i])}><DeleteForeverIcon /></span>
                                <div id="name-container">Name:
                                    <div id="contact-name">{contact?.Name}</div>
                                </div>
                                <div id="email-container">Email:
                                    <div id="email">{contact?.Email}</div>
                                </div>
                                <div>
                                    <button 
                                        onClick={(e: any) => {
                                            e.preventDefault()
                                            handleResendCredRequest(contact.ContactId);
                                        }}
                                        title='resend cred request' className='btn clickable' type="button"><SendTwoToneIcon /></button>
                                </div>
                            </li>
                        )
                        : <li>Empty</li>
                    }
                    <li>
                    <button className='btn btn-secondary' type="button" onClick={() => setNewContactForm(!newContactForm)}>{newContactForm ? '<-' : 'Add a Person'}</button>
                    </li>
                </ul>
                <div>
                    {newContactForm ?
                        <form onSubmit={handleAddNewContact}>
                            <div className='text-input-label-group'>
                                <label htmlFor="dataName">Name:</label>
                                <input
                                    type="text"
                                    id="dataName"
                                    // value={name}
                                    name='contactName'
                                    placeholder="name used in request email"
                                    tabIndex={10}
                                    autoFocus
                                    required={true}
                                    // onChange={e => setContactName(e.target.value)}
                                />
                            </div>
                            <div className='text-input-label-group'>
                                <label htmlFor="dataEmail">Email:</label>
                                <input
                                    type="email"
                                    id="dataEmail"
                                    // value={email}
                                    name='contactEmail'
                                    placeholder="contact email"
                                    tabIndex={10}
                                    required={true}
                                    // onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <button className='btn btn-primary' type="submit">assign the person and send the credential request</button>
                        </form>
                    : undefined }
                </div>
            </fieldset>
        </div>
    )
}