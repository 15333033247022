import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { AlertColor } from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { Link, useLoaderData, useLocation } from "react-router-dom";

import Schedule from '../../components/FieldSet/Schedule';

import Api, { getPortals, getBotsByPortalId, getCredsByPortalId, getConfig, putConfig, getCredByConfigId, deleteConfig } from "@/api/admin-api"
import DestSelect from '@/components/FieldSet/DestinationsSelect';
import EditConfigDetails from '@/components/FieldSet/EditConfigDetails';
import JobField from '@/components/FieldSet/JobField';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Credential, Portal, SnackbarSeverity, Bot, Job, Destination, BotConfig } from '@/constants';

export async function loader({ params }: any) {
    const portals: Array<Portal> = await getPortals();
    const config: BotConfig = await getConfig(params.configId);
    const selectedPortal: Portal = await Api.getPortalByConfigId(params.configId)
    const isDefaultConfig: boolean = await Api.getIsDefaultConfig(params.configId);
    const cred: Credential = await getCredByConfigId(params.configId)
    const destinations: Array<Destination> = await Api.getDestinations()
    return { portals, config, isDefaultConfig, cred, destinations, selectedPortal };
}

export default function EditConfig() {
    const { portals, config, isDefaultConfig, destinations, selectedPortal }: any = useLoaderData();
    const location = useLocation();

    const [bots, setBots] = useState<Array<Bot>>([]);
    const [creds, setCreds] = useState<Array<Credential>>([]);

    const [configState, setConfig] = useState<BotConfig>(config)

    const [selectedPortalState, setSelectedPortalState] = useState<string>(selectedPortal.PortalId.toString())

    const [job, setConfigJob] = useState<any>()

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    useEffect(() => {
        Api.getJob(config.ConfigId).then((resp: Job) => {
            setConfigJob(resp)
        }).catch((resp: any) => {
            if (resp.status === 404)
                console.warn('no job associated with this config')
            else
                console.error('error retreiving a config job')
        })
    }, []);

    useEffect(() => {
        getBotsByPortalId(selectedPortal.PortalId).then((resp: Array<Bot>) => {
            setBots(resp)
        }).catch(()=>{
            handleShowSnackBar(`Error retrieving portal bots.`, SnackbarSeverity.ERROR);
        })

        getCredsByPortalId(selectedPortal.PortalId).then((resp: Array<Credential>) => {
            setCreds(resp)
        }).catch(()=>{
            handleShowSnackBar(`Error retrieving portal creds.`, SnackbarSeverity.ERROR);
        })
    }, [selectedPortal]);

    function saveConfig(e: any) {
        // Prevent the browser from reloading the page
        e.preventDefault();
        
        const formJson = Object.fromEntries(new FormData(e.target).entries());

        if (formJson.botid === '0' || formJson.credid === '0' || formJson.portalid === '0') {
            handleShowSnackBar(`Please complete the form.`, SnackbarSeverity.WARNING);
            return
        }

        // normalize bool inputs
        if (formJson.isDefault) {
            formJson.isDefault = JSON.stringify(true)
        } else {
            formJson.isDefault = JSON.stringify(false)
        }
        if (formJson.isEnabled) {
            formJson.Enabled = JSON.stringify(true)
        } else {
            formJson.Enabled = JSON.stringify(false)
        }

        formJson.ConfigId = config.ConfigId;
        formJson.CronTab = config.CronTab;

        putConfig(config.ConfigId, formJson)
            .then(() => {
                handleShowSnackBar(`Update config success.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                try {
                    const message = JSON.parse(error.responseText)
                    handleShowSnackBar(`Error updating config: ${message.Message}`, SnackbarSeverity.ERROR);
                }
                catch {
                    handleShowSnackBar(`Error updating config: ${error.responseText}`, SnackbarSeverity.ERROR);
                }
        });
    }

    async function handleSelectPortal(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
        setSelectedPortalState(e.target.value)
        setBots(await getBotsByPortalId(e.target.value))
        setCreds(await getCredsByPortalId(e.target.value))
    }

    function handleDeleteConfig(e: any) {
        e.preventDefault();
        if (window.confirm(`Are you sure you want to delete the config"?`)) {
            deleteConfig(config.ConfigId).then(() => {
                handleShowSnackBar(`Deleted the config.`, SnackbarSeverity.SUCCESS)
            }).catch(() => {
                handleShowSnackBar(`Error deleting the config.`, SnackbarSeverity.ERROR)
            })
        }
    }

    // navigate away from edit remove edit from path
    const urlPath = location.pathname
    const toPart = urlPath.replace('/edit', '');

    return (
        <div className='route-container'>
            <div className='entity-header'>
                <h1 className='page-heading'>&quot;{config.Name}&quot; Edit Form</h1>
                <Link to={toPart}><button type="button">{<VisibilityTwoToneIcon />}</button></Link>
            </div>
            <form onSubmit={saveConfig}>
                <div>

                    <EditConfigDetails config={config} isDefault={isDefaultConfig.IsDefaultConfig} />

                    <DestSelect destinations={destinations} selectedDest={config.DestPath} />

                    <fieldset className="form-group border p-3">
                        <legend>Portal Select</legend>
                        <div>
                            <select name="portalid" title='portals' onChange={handleSelectPortal} required={true} value={selectedPortalState}>
                                <option value={0}>Choose a portal</option>
                                {
                                    portals.map((portal: Portal, i: number) => {
                                        return <option key={i} value={portal.PortalId}>{portal.Name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </fieldset>

                    <fieldset className="form-group border p-3">
                        <legend>Bot Select</legend>
                        <div>
                            <select name="botid" title='bots' onChange={
                                (e) => {
                                    console.log(e.target.value)
                                    setConfig({...configState, BotId: parseInt(e.target.value)})
                                    return
                                }} required={true} value={configState.BotId}
                            >
                                <option value={0}>Choose a bot</option>
                                {
                                    bots.map((bot: Bot, i: number) => {
                                        return <option key={i} value={bot.BotId}>{bot.Name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </fieldset>

                    <fieldset className="form-group border p-3">
                        <legend>Cred Select</legend>
                        <div>
                            <select name="credid" title='creds' onChange={e => setConfig({...configState, CredId: parseInt(e.target.value)})} required={true} value={configState.CredId}>
                                <option value={0}>Choose a cred</option>
                                {
                                    creds.map((cred: Credential, i: number) => {
                                        return <option key={i} value={cred.CredId}>{cred.Username}</option>
                                    })
                                }
                            </select>
                        </div>
                    </fieldset>
                    <Schedule cronTab={config.CronTab} isEdit={true} />
                    <button type="submit" id="submit-btn" className="btn btn-primary">{<SaveTwoToneIcon />} Save Config</button>
                    <button type="button" className="btn btn-danger" onClick={handleDeleteConfig}>{<DeleteForeverTwoToneIcon />} Delete Config</button>
                </div>
            </form>
            <JobField job={job} />
        </div>
    )
}
