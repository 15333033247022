import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StartIcon from '@mui/icons-material/Start';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const boxstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type OtpModalProps = {
  open: boolean
  setOpen: any
  sendOtp: any
  otp: string
  setOtp: any
  isScraping: boolean
};

export default function WgOtpModal(props: OtpModalProps) {
  const {
    open,
    setOpen,
    sendOtp,
    otp,
    setOtp,
    isScraping
  } = props;

  return (
    <>
      <button type='button' className='btn btn-secondary' onClick={() => setOpen(true)}>
        <span className='btn-internal'>
          <OpenInNewIcon /><span>Open OTP Form</span>
        </span>
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxstyle}>
          <Typography variant="h6" component="h2">
            Enter the OTP, then click scrape.
          </Typography>
          <TextField label="OTP" variant="standard" value={otp} onChange={(e)=>setOtp(e.target.value)} />
          <div className='submit-group'>
            <Button disabled={isScraping} id='scrape-btn' variant="outlined" onClick={sendOtp}>
              <span className="btn-internal">
                <StartIcon /> Scrape
              </span>
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}