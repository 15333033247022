import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from "@mui/icons-material/Refresh";
import { AlertColor } from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import dayjs from "dayjs";
import React, { useState, useEffect } from 'react';

import Api from "@/api/admin-api";
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity } from "@/constants";

import './component-style.scss'

function LoggerOutput(props: any) {
    // props
    const {
        autoPoll,
        setAutoPoll,
        isScraping,
        downloadPath,
        botCode,
        configId
    } = props;

    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    // state
    const [logs, setLogger] = useState("Waiting ...");
    const [date, setDate] = useState<Date>(new Date(Date.now()));

    // functions
    useEffect(() => {
        if (autoPoll && isScraping) {
            const interval = setInterval(async () => {
                const logPath = getLogPathString()
                await Api.pollLog(logPath)
                    .then((res: any) => {
                        setLogger(res.Log)
                    })
                    .catch((err: any) => {
                        console.error(`Logger error: ${err.statusText}`)
                        setLogger(err.statusText)
                    });
                const logWindow = document.getElementById("log");
                if (logWindow) (logWindow as HTMLFormElement).scrollTop = logWindow.scrollHeight;
            }, 1000);
            return () => {
                clearInterval(interval)
            };
        }
    }, [autoPoll, isScraping, configId]);

    function getLogPathString() {
        const month = date.toLocaleDateString("en-US", {month: "2-digit"})
        const day = date.toLocaleDateString("en-US", {day: "2-digit"})
        return `${downloadPath}logs/${date.getFullYear()}_${month}_${day}_${botCode}_bot_${configId}.log`
    }

    const refreshLog = async () => {
        const logPath = getLogPathString()
        await Api.pollLog(logPath)
            .then((res: any) => {
                setLogger(res.Log)
            })
            .catch((err: any) => {
                console.error(`Logger error: ${err.statusText}`)
                setLogger(err.statusText || err.message)
            });        
        const logWindow = document.getElementById("log");
        if (logWindow) (logWindow as HTMLFormElement).scrollTop = logWindow.scrollHeight;
    }

    function handleCheckScroll() {
        setAutoPoll(!autoPoll)
        if (!isScraping) {
            const timeout = setTimeout(() => {
                if(configId)
                    handleShowSnackBar(`The selected config is not running.`, SnackbarSeverity.INFO)
                else
                    handleShowSnackBar(`Please select a config.`, SnackbarSeverity.INFO)
                setAutoPoll(false)
            }, 250);
            return () => {
                clearTimeout(timeout)
            };
        }
    }

    return (
        <div className='scrolling-logger'>
            <div className="scraper-meta-container">
                <h3>Logs:</h3>
                <span>
                    <FormControlLabel control={<Switch checked={autoPoll} onChange={handleCheckScroll} />} label="Auto Scroll Log" />
                    <span>
                        <span className='btn custom' onClick={()=>setDate(new Date(date.setDate(date.getDate() - 1)))}><ArrowBackIcon /></span>
                        {date.toLocaleDateString()}
                        <span className='btn custom' onClick={()=>
                        {
                            if(dayjs(date).add(1, 'day') > dayjs(Date.now()))
                                return
                            setDate(new Date(date.setDate(date.getDate() + 1)))
                        }
                        }><ArrowForwardIcon /></span>
                    </span>
                    <button className="btn btn-secondary" onClick={refreshLog} type='button'>
                        <span className='btn-internal'>
                            <RefreshIcon />Refresh Log
                        </span>
                    </button>
                </span>
            </div>
            <pre id="log">{logs}</pre>
        </div>
    )
}

export default React.memo(LoggerOutput)
