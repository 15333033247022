let apiServer = ""

if (process.env.NODE_ENV === 'development') {
    console.log('Development mode :-)')
    apiServer = "/"
} else {
    apiServer = "https://fluffycloud.setsight.com/"
}

export default apiServer;
