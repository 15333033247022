import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Api, { getBotByConfigId } from '@/api/admin-api.js';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity, BotConfig, Bot } from '@/constants';
import { getScraperCode } from '@/utilities';

type Props = {
  selectConfig: any
  configId: number
  configs: Array<BotConfig>
  bot: Bot
};

function ConfigSelect(props: Props) {
  const {
    selectConfig,
    configId,
    configs,
    bot
  } = props;

  const navigate = useNavigate();
  const showSnack = useSnackbar();

  const handleShowSnackBar = (message: string, severity: AlertColor) => {
    showSnack(message, severity);
  };

  async function handleNavigateToConfig(configId: number, edit: boolean) {
    if (edit)
        navigate(`/configs/${configId}/edit`)
    else
        navigate(`/configs/${configId}`)
  }

  function handleLoadDefaultBotConfig(e: React.MouseEvent<HTMLButtonElement>) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    Api.getDefaultConfigIdByBotId(bot?.BotId)
      .then((res: any) => {
        handleShowSnackBar(`Default config loaded.`, SnackbarSeverity.SUCCESS)
        const scraperCode = getScraperCode(bot?.RunApiEndpoint);
        navigate(`/bots/${scraperCode}/configs/${res.DefaultConfigId}/debug`);
      }).catch((err: any) => {
        if (err.status === 404)
            handleShowSnackBar(`Default config not found.`, SnackbarSeverity.WARNING)
        else
            handleShowSnackBar(`Error loading default config.`, SnackbarSeverity.ERROR)
    });
  }

  return (
    <div>
      <label className='form-label' htmlFor='configSelect'>Choose a Config</label>
      <div className='flex-container'>
        <select id='configSelect' className='form-select' name="configSelect" onChange={selectConfig} value={configId}>
            <option key={'zero'} value={0}>Select a config</option>
            {configs?.map((config: BotConfig) => <option key={config.ConfigId} value={config.ConfigId}>{config.Name}</option>)}
        </select>
        <div className='btn-wrapper mybtn'>
          {configId != 0 ? <button className='bot-select-option-btn' type='button' title='edit this config' onClick={() => handleNavigateToConfig(configId, true)}><EditIcon /></button>
          : <Link to='/configs/new'><button className='bot-select-option-btn' type='button' title='create new config'><AddCircleIcon /></button></Link>}
        </div>
        <Button
          variant='outlined'
          className='btn-wrapper'
          id='load-default-config'
          type="button"
          name='loadDefaultConfig'
          onClick={handleLoadDefaultBotConfig}
        >Load Default
        </Button>
      </div>
    </div>
  )
}

export default React.memo(ConfigSelect)
