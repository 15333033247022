import ConfigDetails from "./ConfigDetails"

import Schedule from "@/components/FieldSet/Schedule"
import { BotConfig, Portal, Bot } from "@/constants"

type ConfigFieldProps = {
    config: BotConfig
    isDefault: boolean
    portal: Portal
    bot: Bot
}

export default function ConfigFields(props: ConfigFieldProps) {
    const {
        config,
        isDefault,
        portal,
        bot
    } = props

    return (
        <>
            <ConfigDetails config={config} isDefault={isDefault} />

            <fieldset className="form-group border p-3">
                <legend>Portal</legend>
                <div>
                    <div className='text-input-label-group'>
                        <label htmlFor="configname" className='text-label'>Name:</label>
                        {portal.Name}
                    </div>
                    <div className='text-input-label-group'>
                        <label htmlFor="configdescription" className='text-label'>Description:</label>
                        {portal.Description}
                    </div>
                    <div className='text-input-label-group'>
                        <label htmlFor="destinationpath" className='text-label'>Domain:</label>
                        {portal.Domain}
                    </div>
                </div>
            </fieldset>

            <fieldset className="form-group border p-3">
                <legend>Bot</legend>
                <div>
                    <div className='text-input-label-group'>
                            <label htmlFor="configname" className='text-label'>Name:</label>
                            {bot.Name}
                    </div>
                    <div className='text-input-label-group'>
                        <label htmlFor="configdescription" className='text-label'>Description:</label>
                        {bot.Description}
                    </div>
                </div>
            </fieldset>
            <Schedule cronTab={config.CronTab} isEdit={false} />
        </>
    )
}