import { AlertColor } from '@mui/material/Alert';
import React, { useState, useEffect } from 'react';

// utility
import Api from "@/api/admin-api";
import ScraperApi from "@/api/scraper-api";
// import SubmitButton from '@/components/Buttons/ScrapeButton';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeMetrics from "@/components/Metrics";
import ScraperHeader from '@/components/ScraperForm/Header';
import SaveDirectory from '@/components/ScraperForm/SaveDirectory';
// import ScrapeJob from '@/components/ScraperForm/ScrapeJob';
import UsernamePassword from '@/components/ScraperForm/UsernamePassword';
import { SnackbarSeverity, ScraperProps} from "@/constants";

function ViqEmailScrape(props: ScraperProps) {
  const {
    botCode,
    name,
    description,
    config,
    setConfigState,
    cred,
    setCredState,
  } = props;

  // state
  const [isScraping, setIsScraping] = useState<boolean>(false);

  const showSnack = useSnackbar();

  // poll isscraping
  useEffect(() => {
    // make initial api call
    Api.getIsScraping(config?.ConfigId)
      .then((res: any) => {
          setIsScraping(res.IsScraping)
      }).fail(() => {
          setIsScraping(false)
      });
    // if a config is selected poll api every 30 seconds unless not scraping
    if (config) {
      const isScrapingInterval = setInterval(() => {
        Api.getIsScraping(config?.ConfigId)
          .then((res: any) => {
              setIsScraping(res.IsScraping)
              if (!res.IsScraping) {
                  clearInterval(isScrapingInterval)
              }
          }).fail(() => {
              clearInterval(isScrapingInterval)
              setIsScraping(false)
          });
      }, 30000);
      return () => {
          clearInterval(isScrapingInterval)
      };
    }
  }, [isScraping, config?.ConfigId]);

  const handleShowSnackBar = (message: string, severity: AlertColor) => {
    showSnack(message, severity);
  };

  // functions
  function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data as json
    // const formJson = Object.fromEntries(new FormData(e.target).entries());

    if (config?.ConfigId && config.ConfigId > 0) {
      ScraperApi.configViqEmail(config?.ConfigId)
        .then(() => {
          setIsScraping(true)
          handleShowSnackBar(`Started bot config: "${config?.Name}".`, SnackbarSeverity.INFO);
        }).catch((err: any) => {
          setIsScraping(false)
          let message = JSON.parse(err.responseText).Message
          if (!message)
            message = 'Error'
          handleShowSnackBar(message, SnackbarSeverity.ERROR);
      });
    } else {
      handleShowSnackBar('First create a config?.', SnackbarSeverity.INFO)
    }
  }
  
  return (
    <div className='form-container'>
      <ScraperHeader name={name} description={description} />
      <form method="post" onSubmit={handleSubmit}>
        <UsernamePassword cred={cred} setCred={setCredState} />
        {/* <ScrapeJob readonly={false} job={job} setJob={setJobState} placeHolder='["report-title","report-title","report-title"...]' /> */}
        <SaveDirectory config={config} setConfig={setConfigState} />
        <div className="submit-group">
          {/* <SubmitButton isScraping={isScraping} /> */}
            <button disabled>Not Ready</button>
        </div>
      </form>
      <ScrapeMetrics config={config} isScraping={isScraping} botCode={botCode} />
    </div>
  )
}

export default React.memo(ViqEmailScrape)
