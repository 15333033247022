import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { memo } from 'react';

type Props = {
    options: Array<string>
    onChange: any,
    selectedClients: Array<string>
    disabled: boolean
}
function ClientsMultiSelect(props: Props) {

    const { options, onChange, selectedClients, disabled } = props;

    return (
        <div className='client-select'>
            <Autocomplete
                onChange={(event, value) => {
                    return onChange(event, value)
                }}
                disabled={disabled}
                sx={{ width: 300 }}
                multiple
                id="tags-standard"
                options={options}
                getOptionLabel={(option) => option}
                value={selectedClients}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                    />
                )}
            />
        </div>
    );
}

export default memo(ClientsMultiSelect)
