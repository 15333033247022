import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

import DataTable from '@/components/Table/DataTable';
import { Job, POLVdsIds } from '@/constants';

type JobEditModalProps = {
    isOpen: boolean
    setIsOpen: any
    polVds: Array<POLVdsIds>
    clientShortCodes: Array<string>
    job: Job
    setJobState: any
}

export default function JobEditModal(props: JobEditModalProps) {
    const { isOpen, setIsOpen, polVds, clientShortCodes, job, setJobState } = props;

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
          children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
      ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClose = () => {
        setIsOpen(false);
    };
    
    return (
        <Dialog
            maxWidth={'xl'}
            open={isOpen}
            onClose={handleClose}
            // TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              VIQ Job Edit
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <SaveIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <span id='viq-job-edit'>
            <DataTable loading={false} polvds={polVds} jobstate={job?.JobString ? job : {ConfigId: 0, Name: '', Description: '', JobString: '{}', CreateDate: '', ModifiedBy: '', ModifiedDate: '', ModifiedReason: ''}} setjob={setJobState} clients={clientShortCodes} />
        </span>
        </Dialog>
    )
}