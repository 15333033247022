import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import DangerousTwoToneIcon from '@mui/icons-material/DangerousTwoTone';
import RefreshIcon from "@mui/icons-material/Refresh";
import { AlertColor } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';

import Api from "@/api/admin-api";
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import LoggerOutput from "@/components/LoggerOutput";
import { SnackbarSeverity, ConfigRunMetric, BotConfig } from "@/constants";

import './component-style.scss'

type MetricsProps = {
    config: BotConfig | null
    isScraping: boolean
    botCode: string | undefined
};

const initialState = {
    ConfigId: 0, CurrentMessage: '',
    LastRunStartTime: null,
    LastRunEndTime: null,
    WasSuccess: false,
    Owner: ''
}

function ScrapeMetrics(props: MetricsProps) {
    const {
        config,
        isScraping,
        botCode
    } = props;

    const [configMetric, setConfigMetric] = useState<ConfigRunMetric>(initialState);

    const [botStatus, setBotStatus] = useState<any>('');
    const [elapsedTime, setElapsedTime] = useState<string>('')
    const [autoPoll, setAutoPoll] = useState<boolean>(false)

    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    // elapsed time
    useEffect(() => {
        const timeElapsedInterval = setInterval(() => {
            if (!configMetric.LastRunEndTime && !configMetric.LastRunStartTime) {
                setElapsedTime('')
                clearInterval(timeElapsedInterval)
            }
            else if (!configMetric.LastRunEndTime && isScraping) {
                if(configMetric.LastRunStartTime) {
                    const elapsedTime = new Date(new Date().getTime() - new Date(configMetric.LastRunStartTime).getTime())
                    const parts = [
                        elapsedTime.getUTCHours(),
                        elapsedTime.getUTCMinutes(),
                        elapsedTime.getUTCSeconds()
                    ]
                    // Zero-pad
                    setElapsedTime(parts.map(s => String(s).padStart(2,'0')).join(':'))
                }
            }
            else {
                if(configMetric.LastRunEndTime && configMetric.LastRunStartTime) {
                    const elapsedTime = new Date(new Date(configMetric.LastRunEndTime).getTime() - new Date(configMetric.LastRunStartTime).getTime())
                    const parts = [
                        elapsedTime.getUTCHours(),
                        elapsedTime.getUTCMinutes(),
                        elapsedTime.getUTCSeconds()
                    ]
                    setElapsedTime(parts.map(s => String(s).padStart(2,'0')).join(':'))
                    clearInterval(timeElapsedInterval)
                }
            }
        }, 1000);

        return () => {
            clearInterval(timeElapsedInterval)
        };
    }, [configMetric, isScraping]);

    // config metrics
    useEffect(() => {
        let ignore = false;
        if (config?.ConfigId) {
            Api.getMetric(config?.ConfigId)
                .then((res: ConfigRunMetric) => {
                    if (!ignore) {
                        setConfigMetric(res);
                        setBotStatus(isScraping ? <CircularProgress /> : res.WasSuccess ? <CheckCircleTwoToneIcon color='success' /> : <DangerousTwoToneIcon color='error' />)
                        setAutoPoll(false)
                    }
                }).catch((err: any) => {
                    if (err.status === 404) {
                        setConfigMetric(initialState)
                    }
                    else {
                        setConfigMetric({...initialState, CurrentMessage: err.statusText})
                        handleShowSnackBar(err.statusText, SnackbarSeverity.ERROR)
                    }
                    setAutoPoll(false)
                });
        }
        else {
            setConfigMetric(initialState)
        }
        return () => {
            ignore = true;
        }
    }, [isScraping, config?.ConfigId, configMetric.WasSuccess]);

    // functions
    function toDateTimeString(endTime: Date | null) {
        if (endTime) {
            const d = new Date(endTime);
            return d.toLocaleString();
        } else {
            return null
        }
    }

    function handleCheckMetrics(e: any) {
        e.preventDefault();
        if (config?.ConfigId)
            Api.getMetric(config?.ConfigId)
                .then((res: ConfigRunMetric) => {
                    setConfigMetric(res)
                    setBotStatus(isScraping ? <CircularProgress /> : res.WasSuccess ? <CheckCircleTwoToneIcon color='success' /> : <DangerousTwoToneIcon color='error' />)
                }).catch((err: any) => {
                    if(err.status === 404) {
                        setConfigMetric({...initialState, CurrentMessage: `Run the bot to see metrics.`})
                        setBotStatus('')
                    }
                    else
                        setConfigMetric({...initialState, CurrentMessage: err.statusText})
                });
        else
            setConfigMetric({...initialState, CurrentMessage: `Please select a config.`})
    }

    return (
        <div className='metrics'>
            <div className="scraper-meta-container">
                <h3>Last Run Metrics:</h3>
                <button
                    id='checkMetrics'
                    className='btn btn-secondary'
                    type="button"
                    name='checkMetrics'
                    onClick={handleCheckMetrics}
                >
                    <span className='btn-internal'>
                        <RefreshIcon />Refresh Metrics
                    </span>
                </button>
            </div>
            <table className='output-panel'>
                <tbody>
                    <tr className='output-row'>
                        <td className='output-header'>Last message:</td>
                        <td className='output-text'>
                            {configMetric.CurrentMessage}
                        </td>
                    </tr>
                    <tr className='output-row'>
                        <td className='output-header'>Last start date:</td>
                        <td className='output-text'>
                            {toDateTimeString(configMetric.LastRunStartTime)}
                        </td>
                    </tr>
                    <tr className='output-row'>
                        <td className='output-header'>Last end date:</td>
                        <td className='output-text'>
                            {toDateTimeString(configMetric.LastRunEndTime)}
                        </td>
                    </tr>
                    <tr className='output-row'>
                        <td className='output-header'>Elapsed time:</td>
                        <td className='output-text'>
                            {elapsedTime}
                        </td>
                    </tr>
                    <tr className='output-row'>
                        <td className='output-header'>Run owner:</td>
                        <td className='output-text'>
                            {configMetric.Owner}
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr><th>Bot Status</th></tr>
                    <tr className='output-row'>
                        <td className='output-header'>Status:</td>
                        <td className='output-text'>{botStatus}</td>
                    </tr>
                </tbody>
            </table>
            <LoggerOutput downloadPath={config?.DestPath} autoPoll={autoPoll} setAutoPoll={setAutoPoll} isScraping={isScraping} botCode={botCode} configId={config?.ConfigId} />
        </div>
    )
}

export default React.memo(ScrapeMetrics)
