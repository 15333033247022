import $ from 'jquery';
import apiServer from "./serverInfo";

export const getClientShortCodesByIngestionType = ingestType => {
    return $.ajax({
        url: `${apiServer}api/scrape/setup/clients/${ingestType}`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
    });
}

export const getClientShortCoes = () => {
    return $.ajax({
        url: `${apiServer}api/scrape/setup/clients`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
    });
}
