import { AlertColor } from '@mui/material/Alert';
import React, { useState, useEffect } from 'react';

import Api from "@/api/admin-api.js";
import ScraperApi from "@/api/scraper-api";
import SubmitButton from '@/components/Buttons/ScrapeButton';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeMetrics from "@/components/Metrics";
import ScraperHeader from '@/components/ScraperForm/Header';
import SaveDirectory from '@/components/ScraperForm/SaveDirectory';
import UsernamePassword from '@/components/ScraperForm/UsernamePassword';
import { SnackbarSeverity, ScraperProps } from "@/constants";

function DgScrapeForm(props: ScraperProps) {
  const {
    botCode,
    name,
    description,
    config,
    setConfigState,
    cred,
    setCredState,
    // job,
    // setJobState
  } = props;

  // state
  const [isScraping, setIsScraping] = useState<boolean>(false);

  // poll isscraping
  useEffect(() => {
    // make initial api call
    Api.getIsScraping(config?.ConfigId)
      .then((res: any) => {
          setIsScraping(res.IsScraping)
      }).fail(() => {
          setIsScraping(false)
      });
    // if a config is selected poll api every 30 seconds unless not scraping
    if (config) {
      const isScrapingInterval = setInterval(() => {
        Api.getIsScraping(config?.ConfigId)
          .then((res: any) => {
              setIsScraping(res.IsScraping)
              if (!res.IsScraping) {
                  clearInterval(isScrapingInterval)
              }
          }).fail(() => {
              clearInterval(isScrapingInterval)
              setIsScraping(false)
          });
      }, 30000);
      return () => {
          clearInterval(isScrapingInterval)
      };
    }
  }, [isScraping, config?.ConfigId]);

  const showSnack = useSnackbar();

  const handleShowSnackBar = (message: string, severity: AlertColor) => {
    showSnack(message, severity);
  };
  
  function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data as json
    const formJson = Object.fromEntries(new FormData(e.target).entries());
    if (config?.ConfigId && config.ConfigId > 0) {
      ScraperApi.scrapeDg(config?.ConfigId, formJson)
        .then((data: any, textStatus: string, xhr: any) => {
          setIsScraping(true)
          handleShowSnackBar(`Started bot config: "${config?.Name}".`, SnackbarSeverity.INFO);
          // console.log(xhr.getResponseHeader('Location'))
        }).catch((err: any) => {
          setIsScraping(false)
          handleShowSnackBar(`Error sarting bot config: "${config?.Name}".`, SnackbarSeverity.ERROR);
      });
    } else {
      handleShowSnackBar(`First create a config?.`, SnackbarSeverity.INFO)
    }
  }
  
  return (
    <div>
      <div className='form-container'>
        <ScraperHeader name={name} description={description} />
        <form method="post" onSubmit={handleSubmit}>
          <UsernamePassword cred={cred} setCred={setCredState} />
          <SaveDirectory config={config} setConfig={setConfigState} />
          {/* <ScrapeJob jobString='' setJobString={} /> */}
          <div className="submit-group">
            <SubmitButton isScraping={isScraping} />
            <fieldset>
              <legend>Override Previous Session</legend>
              <div className='form-check form-check-inline'>
                <label className='form-check-label' htmlFor='yes-killsesh'>
                  <input
                    id='yes-killsesh'
                    className='form-check-input'
                    type='radio'
                    name='killprevsession'
                    value="true"
                    defaultChecked={true}
                    required={true} />Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <label className='form-check-label' htmlFor='no-killsesh'>
                  <input
                    id='no-killsesh'
                    className='form-check-input'
                    type='radio'
                    name='killprevsession'
                    value="false"
                    required={true} />No
                </label>
              </div>
            </fieldset>
          </div>
        </form>
        <ScrapeMetrics config={config} isScraping={isScraping} botCode={botCode} />
      </div>
    </div>
  )
}

export default React.memo(DgScrapeForm)
