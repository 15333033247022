import { AlertColor } from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Link, useLoaderData } from "react-router-dom";

import { getPortals } from '@/api/admin-api';
import Api, { getCampCreatorAuth, getCamps } from '@/api/campaign-api';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Campaign, Portal, SnackbarSeverity } from '@/constants';

import "@/routes/Campaign/campaigns.scss"

type CampaignEntry = {
    isAuthenticated: boolean
};

export async function loader({ params }: any) {
    await getCampCreatorAuth();
    const portals = await getPortals();
    const camps = await getCamps();
    return { portals, camps };
}  

export default function CampaignEntry() {
    const { portals, camps }: any = useLoaderData();

    const [availPortals, setAvailPortals] = useState<Array<Portal>>(portals);
    const [portalsBag, setPortalsBag] = useState<Array<Portal>>([]);
    const [notListed, setNotListed] = useState<boolean>(false);

    // const [myCamps, setMyCamps] = useState<Array<Campaign>>([]);

    const [campName, setCampName] = useState<string>('');
    const [campDesc, setCampDesc] = useState<string>('');
    const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()));
    const [fileType, setFileType] = useState<string>('None');
    const [name, setContactName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    // useEffect(() => {
    //     Api.getMyCamps()
    //         .then((res: Campaign[]) => {
    //             setMyCamps(res)
    //         }).catch((err: any) => {
    //             console.error(`Camps error: ${err.statusText}`)
    //     });
    // }, [myCamps.length]);

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };  

    function saveCampaign(e: any) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        const formJson = Object.fromEntries(new FormData(e.target).entries());

        // 4/26/2023 9:34:41 PM
        formJson.StartDate = startDate.format('MM/DD/YYYY HH:mm:ss A');
        formJson.EndDate = endDate.format('MM/DD/YYYY HH:mm:ss A');
        if (notListed)
            formJson.Portals = '[0]';
        else
            formJson.Portals = JSON.stringify(portalsBag.map(({PortalId}) => (PortalId)));
        if (window.confirm(`Are you sure you want create the campaign: "${formJson.campaignName}" and invite ${formJson.contactEmail} to add credentials?`)) {
            Api.campaignInvite(formJson)
                .then((res: any) => {
                    handleShowSnackBar(`Created campaign "${res.Name}" and invited "${formJson.contactEmail}".`, SnackbarSeverity.SUCCESS);
                    // myCamps.length ++
                }).catch((error: any) => {
                    console.error(error.responseText)
                    handleShowSnackBar(`Error saving campaign: ${error.statusText}`, SnackbarSeverity.ERROR);
            });
        }
    }

    function handleAddPortal(e: any) {
        const index = portalsBag.findIndex(x => x.PortalId == e.PortalId);
        index === -1 ? setPortalsBag(prev => [...prev, e]) : console.log("portal already exists")
        setAvailPortals(availPortals.filter(item => item.PortalId !== e.PortalId))
    }

    function handleRemovePortal(e: any) {
        setPortalsBag(portalsBag.filter(item => item.PortalId !== e.PortalId))
        const index = availPortals.findIndex((x: Portal) => x.PortalId == e.PortalId);
        index === -1 ? setAvailPortals(prev => [...prev, e]) : console.log("portal already exists")
    }

    function handleLogout() {
        sessionStorage.removeItem('ss3apitoken')
        window.location.replace('https://setsight.com/')
    }

    return (
        <div id="camp-entry">
            <div id='campaign-entry-container'>
                <div className='campaign-entry-header'>
                    <div>
                        <div className="heading">NEW CAMPAIGN ENTRY</div>
                        <div className="subheading">Create or edit a campaign and send the credential request.</div>
                    </div>
                    <img src="../../HTML/images/logo_vericast.png" height={25} alt="logo" />
                    <button type="button" className='btn btn-light logout-btn' onClick={handleLogout}>Logout</button>
                </div>

                <form onSubmit={saveCampaign}>
                    <div id='campaign-entry-form'>
                        <fieldset className="form-group border p-3">
                            <legend>Details</legend>
                            <div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="dataCampaignName" className='text-label'>Campaign Name</label>
                                    <input
                                        type="text"
                                        id="dataCampaignName"
                                        name='campaignName'
                                        value={campName}
                                        placeholder="your campaign name"
                                        tabIndex={10}
                                        autoFocus
                                        required={true}
                                        onChange={e => setCampName(e.target.value)}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="dataCampaignDescription" className='text-label'>Campaign Description</label>
                                    <input
                                        type="textarea"
                                        id="dataCampaignDescription"
                                        name='description'
                                        value={campDesc}
                                        placeholder="your campaign description"
                                        tabIndex={10}
                                        required={true}
                                        onChange={e => setCampDesc(e.target.value)}
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="form-group border p-3">
                            <legend>Time Span</legend>
                            <div className="form-group" id="calendar-pickers">
                                <div className="datepicker">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="start date of campaign" defaultValue={dayjs(new Date())} onChange={(value, context) => setStartDate(dayjs(value))} value={startDate}/>
                                    </LocalizationProvider>
                                </div>
                                <div className='datepicker'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="end date of campaign" onChange={(value, context) => setEndDate(dayjs(value))} value={endDate}/>
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="form-group border p-3">
                            <legend>Portal(s) Selection</legend>
                            {notListed ? '' : 
                            <div id="portals" className="form-group">
                                <div className="portal-bag">Available Portal(s)
                                    <ul>
                                        {availPortals.length > 0 ?
                                        availPortals.map((portal: Portal, i: number) =>
                                            <li className='portals-item' key={portal?.PortalId}>{portal?.Name}<button id={`portal-${i}`} type="button" onClick={() => handleAddPortal(availPortals[i])}>Add</button></li>
                                        )
                                        : <li>None</li>
                                        }
                                    </ul>
                                </div>
                                <div className="portal-bag">Selected Portal(s)
                                    <ul>
                                        {portalsBag.length > 0 ?
                                            portalsBag.map((portal: Portal, i: number) =>
                                                <li className='portals-item' key={portal?.PortalId}>{portal?.Name}<button type="button" onClick={() => handleRemovePortal(portalsBag[i])}>Remove</button></li>
                                            )
                                            : <li>Empty</li>
                                        }
                                    </ul>
                                </div>
                            </div>}
                            <label htmlFor="notlisted-check"><input id="notlisted-check" type="checkbox" name="notlist" value="[0]" 
                                onChange={
                                    () => {
                                        if (!notListed)
                                            handleShowSnackBar(`Miscellaneous credential request mode.`, SnackbarSeverity.INFO);
                                        setNotListed(!notListed)}
                                    } 
                                /> Not Listed/Miscellaneous</label>
                        </fieldset>
                        
                        <fieldset className="form-group border p-3" hidden>
                            <legend>File Type Selection</legend>
                            <div className='select-container'>
                                <select title='File Type' id='fileTypeSelect' name="fileType" className='form-select' onChange={e => setFileType(e.target.value)} value={fileType}>
                                    <option value='None'>None</option>
                                    <option value='CSV'>CSV</option>
                                    <option value='PDF'>PDF</option>
                                    <option value='XLSX'>XLSX</option>
                                </select>
                            </div>
                        </fieldset>
                        <fieldset className="form-group border p-3">
                            <legend>Vendor Contact Information</legend>
                            <div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="dataName" className='text-label'>Vendor Name</label>
                                    <input
                                        type="text"
                                        id="dataName"
                                        value={name}
                                        name='contactName'
                                        placeholder="the vendor's name"
                                        tabIndex={10}
                                        required={true}
                                        onChange={e => setContactName(e.target.value)}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="dataEmail" className='text-label'>Vendor Email</label>
                                    <input
                                        type="email"
                                        id="dataEmail"
                                        value={email}
                                        name='contactEmail'
                                        placeholder="the vendor's email"
                                        tabIndex={10}
                                        required={true}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <button type="submit" id="camp-submit-btn">Assign &quot;{name ? name : 'None'}&quot; to the campaign and request credentials</button>
                </form>
            </div>
            <div id="mycamps-container">
                <div className='campaign-entry-header'>
                    <h2 className="heading">MY CAMPAIGNS</h2>
                </div>
                <ul>
                    {camps.length > 0 ?
                        camps.sort((a: Campaign, b: Campaign) => a.CampaignId - b.CampaignId).map((camp: Campaign, i: number) =>
                            <li key={camp?.CampaignId}>
                                <div>{i+1}) {camp?.Name}</div>
                                <div>
                                    <Link to={`/entry/campaigns/${camp.CampaignId}`}>
                                        <button id={'edit-campaign' + i} type="button" className='btn btn-info'>View/Edit</button>
                                    </Link>
                                </div>
                            </li>
                        )
                    : <li>None</li>
                    }
                </ul>
            </div>
        </div>
    )
}
