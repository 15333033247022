
export function validateDownloadDir(path: string) {
    const regex = new RegExp('^[A-Za-z]:[\\,/]?')
    if (path.slice(-1) !== "\\" && path.slice(-1) !== "/") {    // append directory slash
        path += "\\"
    }
    if (path === '' || path === '\\') {                         // none do nothing
        path = ''
    } else if (regex.test(path.slice(0,2))) {                   // fix redundant slashes in directory
        path = path.replace(/[/,\\]{2,}/g, '\\')
    } else {                                                    // fix redundant slashes in network UNC
        path = path.replace(/[/,\\]{2,}/g, '\\').replace(/^[\\,/]*/g, '\\\\')
    }
    return path.trim()
}

export function getScraperCode(endpoint: string | undefined) {
    if(endpoint)
        return endpoint.split('/')[endpoint.split('/').length - 1]
}

export function millisToMinutesAndSeconds(millis: number) {
    const minutes: number = Math.floor(millis / 60000);
    const seconds: any = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

// String.prototype.hexEncode = function() {
export function hexEncode(input: string) {
    let hex, i;
    let result = "";
    for (i=0; i<input.length; i++) {
        hex = input.charCodeAt(i).toString(16);
        result += ("000"+hex).slice(-4);
    }
    return result
}

// String.prototype.hexDecode = function() {
export function hexDecode(input: string) {
    let j;
    const hexes = input.match(/.{1,4}/g) || [];
    let back = "";
    for(j = 0; j<hexes.length; j++) {
        back += String.fromCharCode(parseInt(hexes[j], 16));
    }
    return back;
}

export function copyToClipboard(text: string) {
    if ('clipboard' in navigator) {
        navigator.clipboard.writeText(text);
    } else {
        document.execCommand('copy', true, text);
    }
}
