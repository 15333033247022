import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";

import { getCreds, deleteCred } from '@/api/admin-api';
import AddBtn from '@/components/Buttons/AddBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Credential, SnackbarSeverity} from '@/constants';

export async function loader() {
    const creds: Array<Credential> = await getCreds();
    return { creds };
}  

export default function Credentials() {
    const { creds }: any = useLoaderData();
    const showSnack = useSnackbar();

    const [credsState, setCredsState] = useState<Array<Credential>>(creds);

    const navigate = useNavigate();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    function handleDeleteCred(event: React.MouseEvent<HTMLElement>, credId: number) {
        event.stopPropagation();
        if (window.confirm(`Are you sure you'd like to delete the cred?`)) {
            deleteCred(credId).then(() => {
                setCredsState(credsState.filter(a => a.CredId !== credId));
                handleShowSnackBar(`Successfully deleted the cred.`, SnackbarSeverity.SUCCESS);
            }).catch((err: any) => {
                let message = ''
                try {
                    message = JSON.parse(err.responseText).Message
                } catch {
                    message = 'unexpected error'
                } finally {
                    handleShowSnackBar(`Delete fail: ${message}.`, SnackbarSeverity.ERROR);
                }
            })
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));

    function handleNavToCred(event: React.MouseEvent<HTMLElement>, credId: number) {
        event.stopPropagation();
        navigate(`/credentials/${credId}`)
    }

    return (
        <div className='route-container'>
            <div className='page-heading'>
                <h1>Credentials</h1>
                <AddBtn lable='Add a Credential' handleAdd={() => navigate('/credentials/new')} />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Username</StyledTableCell>
                            <StyledTableCell align="right">Last Updated</StyledTableCell>
                            <StyledTableCell align="right">Status</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {credsState.map((cred: Credential) => (
                            <TableRow
                                hover
                                key={cred.CredId}
                                sx={{
                                    '*': { cursor: 'pointer' }
                                }}
                                onClick={(e) => handleNavToCred(e, cred.CredId)}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {cred.Username}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {cred.LastUpdated}
                                </StyledTableCell>
                                <StyledTableCell align='right' >
                                    {cred.Status ? <CheckRoundedIcon color='success' /> : <WarningRoundedIcon color='error' />}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <div className='action-set'>
                                        <div className='btn btn-danger' onClick={(e: React.MouseEvent<HTMLElement>) => handleDeleteCred(e, cred.CredId)}>
                                            <DeleteForeverTwoToneIcon />
                                        </div>
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}