import $ from 'jquery';
import apiServer from "./serverInfo";

const campapi = {
    oauthLogin: (accessToken) => {
        return $.ajax({
            url: apiServer + 'api/scrape/token?',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: {password: accessToken, grant_type: 'password'},
            xhrFields: {
                withCredentials: true
            }
        });
    },

    // api/scrape/creds/invite
    campaignInvite: (dto) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'POST',
            url: apiServer + "api/scrape/creds/invite",
            headers: headers,
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    // api/scrape/creds/invite?campaignId={campId}
    campaignCredInvite: (campId, dto) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'POST',
            url: `${apiServer}api/scrape/creds/invite?campId=${campId}`,
            headers: headers,
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    // api/scrape/campaigns/mycamps
    getMyCamps: () => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'GET',
            url: apiServer + "api/scrape/campaigns/mycamps",
            headers: headers,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    // api/scrape/creds/camp/ports
    savePortalCred: (dto) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'POST',
            url: apiServer + "api/scrape/creds/camp/ports",
            headers: headers,
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    saveMiscCampCred: (dto) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'POST',
            url: apiServer + "api/scrape/creds/camp/misc",
            headers: headers,
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    getCampCreds: (campaignId) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'GET',
            url: apiServer + "api/scrape/creds",
            headers: headers,
            data: {campId: campaignId},
            xhrFields: {
                withCredentials: true
            }
        })
    },

    getCampMiscCred: (campaignId) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'GET',
            url: apiServer + "api/scrape/creds/camp/misc",
            headers: headers,
            data: {campId: campaignId},
            xhrFields: {
                withCredentials: true
            }
        })
    },

    deleteCred: (credId) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'DELETE',
            url: `${apiServer}api/scrape/creds/${credId}`,
            headers: headers,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    getCampPortCred: (campId, portalId) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'GET',
            url: apiServer + "api/scrape/creds",
            headers: headers,
            data: {campId: campId, portalId: portalId},
            xhrFields: {
                withCredentials: true
            }
        })
    },

    putCampaign: (campId, dto) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'PUT',
            url: `${apiServer}api/scrape/campaigns/${campId}`,
            headers: headers,
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        })
    },

    saveCampPorts: (campId, portsToAdd, portsToRemove) => {
        var token = sessionStorage.getItem('ss3apitoken');
        var headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        return $.ajax({
            type: 'PUT',
            url: `${apiServer}api/scrape/campaigns/${campId}/ports`,
            headers: headers,
            data: {portsToAdd: portsToAdd, portsToRemove: portsToRemove},
            xhrFields: {
                withCredentials: true
            }
        })
    }
};

// campaigns api
export const getCamps = () => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/campaigns`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const getCampaignPortals = (campaignId) => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: apiServer + `api/scrape/portals`,
        headers: headers,
        data: {campaignId: campaignId},
        xhrFields: {
            withCredentials: true
        }
    })
};

export const getCampaign = (campaignId) => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/campaigns/${campaignId}`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

// export const getCampaignPortalContact = (campaignId) => {
//     var token = sessionStorage.getItem('ss3apitoken');
//     var headers = {};
//     if (token) {
//         headers.Authorization = 'Bearer ' + token;
//     }
//     return $.ajax({
//         type: 'GET',
//         url: `${apiServer}api/scrape/campaigns/${campaignId}`,
//         headers: headers
//     })
// }

export const getCampContacts = (campaignId) => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/contacts/campaign/${campaignId}`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const deleteCampContact = (campaignId, contactId) => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'DELETE',
        url: `${apiServer}api/scrape/contacts/campaign/${campaignId}?contactId=${contactId}`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const deleteContact = (contactId) => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'DELETE',
        url: `${apiServer}api/scrape/contacts/${contactId}`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const getCampCreatorAuth = () => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/access/camp`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const getCredCreatorAuth = () => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/access/cred`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

// root access
export const getRootAccess = () => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/access/manager`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const deleteCampaign = (campId) => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'DELETE',
        url: `${apiServer}api/scrape/campaigns/${campId}`,
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export default campapi;