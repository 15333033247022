import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SaveIcon from '@mui/icons-material/Save';
import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { Link, useLoaderData } from "react-router-dom";

import Api, { getConfig, putJob, deleteJob } from "@/api/admin-api"
import DeleteBtn from '@/components/Buttons/DeleteBtn';
// import SaveBtn from '@/components/Buttons/SaveBtn';
import EditConfigJobModal from '@/components/FieldSet/EditJobModal';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeJob from '@/components/ScraperForm/ScrapeJob';
import { SnackbarSeverity, Job, BotConfig, UpdateJobDto } from '@/constants';

export async function loader({ params }: any) {
    const config: BotConfig = await getConfig(params.configId);
    const job: Job = await Api.getJob(params.configId)
    return { config, job};
}

export default function EditConfigJob() {
    const { job, config }: any = useLoaderData();

    const [jobState, setJobState] = useState<Job>(job)
    const [reasonText, setReason] = useState<string>('')

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    function handleUpdateJob(e: any) {
        e.preventDefault();
        const updateJobPayload: UpdateJobDto = {
            ConfigId: jobState.ConfigId,
            Name: jobState.Name,
            Description: jobState.Description,
            JobString: jobState.JobString,
            ModifiedReason: reasonText
        }

        putJob(config.ConfigId, updateJobPayload)
            .then(() => {
                Api.getJob(config.ConfigId).then((res: Job) => {
                    setJobState(res)
                }).catch(() => {
                    console.error('problem reloading job data')
                })
                handleClose()
                handleShowSnackBar(`Successfully updated the config job.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleClose()
                handleShowSnackBar(`Error updating the config job.`, SnackbarSeverity.ERROR);
                console.error(error.responseText)
        });
    }

    function handleDeleteJob(configId: number) {
        deleteJob(configId).then(() => {
            handleShowSnackBar(`Successfully deleted the config job.`, SnackbarSeverity.SUCCESS);
        }).catch(() => {
            handleShowSnackBar(`Error deleting the config job.`, SnackbarSeverity.ERROR);
        })
    }

    return (
        <div>
            <h1 className='page-heading'>{config.Name} Job Edit Form</h1>
            <Link to='edit'>
                <button type="button" className='btn btn-secondary'>
                    <span className='btn-internal'><FontAwesomeIcon icon={faTableList} />Advanced Job Editor</span>
                </button>
            </Link>
            <form>
                <fieldset className="form-group border p-3">
                    <legend>Job Info</legend>
                    {job ? 
                        <div>
                            <div className='text-input-label-group'>
                                <label className="text-label" htmlFor='createdate'>Created Date</label>
                                <input disabled type='text' value={new Date(Date.parse(jobState.CreateDate)).toLocaleString()} id='createdate' />
                            </div>
                            <div className='text-input-label-group'>
                                <label className="text-label" htmlFor='modifieddate'>Modified Date</label>
                                <input disabled type='text' value={new Date(Date.parse(jobState.ModifiedDate)).toLocaleString()} id='modifieddate' />
                            </div>
                            <div className='text-input-label-group'>
                                <label className="text-label" htmlFor='modifiedby'>Modified By</label>
                                <input disabled type='text' value={jobState.ModifiedBy} id='modifiedby' />
                            </div>
                            <div className='text-input-label-group'>
                                <label className="text-label" htmlFor='modifiedreason'>Modified Reason</label>
                                <input disabled type='text' value={jobState.ModifiedReason} id='modifiedreason' />
                            </div>
                            <div className='text-input-label-group'>
                                <label htmlFor="jobname" className='text-label'>Name:</label>
                                <input
                                    type="text"
                                    id="jobname"
                                    name='name'
                                    autoFocus
                                    required={true}
                                    value={jobState.Name}
                                    onChange={e => setJobState({...job, Name: e.target.value})}
                                />
                            </div>
                            <div className='text-input-label-group'>
                                <label htmlFor="jobdescription" className='text-label'>Description:</label>
                                <input
                                    type="textarea"
                                    id="jobdescription"
                                    name='description'
                                    required={true}
                                    value={jobState.Description}
                                    onChange={e => setJobState({...job, Description: e.target.value})}
                                />
                            </div>
                            <ScrapeJob disabled={false} job={jobState} setJob={setJobState} placeHolder='n/a' />
                            <button type="button" id="submit-btn" className='btn btn-primary' onClick={handleOpen}>
                                <span className="btn-internal">
                                    <SaveIcon />Save Changes
                                </span>
                            </button>
                            <DeleteBtn handleDelete={() => handleDeleteJob(config.ConfigId)} text='Delete Config Job' />
                            <EditConfigJobModal isOpen={open} setClose={handleClose} handleUpdateJob={handleUpdateJob} reasonText={reasonText} setReason={setReason} />
                        </div>
                    :
                        <div>None</div>
                    }
                </fieldset>
            </form>
        </div>
    )
}
