import type { AlertColor } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { createContext, useContext, useState, useEffect } from 'react';

type ShowSnackbarHandler = (message: string, severity: AlertColor) => void;

export interface SnackbarMessage {
    message: string;
    severity: AlertColor
    key: number;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarContext = createContext<ShowSnackbarHandler>(() => {
    console.error('Component is not wrapped with a SnackbarProvider.');
});

const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children, }) => {
    const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined,);

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpen(false);
        }
      }, [snackPack, messageInfo, open]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const showSnackbar: ShowSnackbarHandler = (message, severity) => {
    setSnackPack((prev) => [...prev, { message, severity, key: new Date().getTime() }]);
  };

  return (
    <>
      <Snackbar 
        open={open}
        autoHideDuration={6000}
        onClose={handleClose} 
        key={messageInfo ? messageInfo.key : undefined}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo?.message}
      >
        <Alert onClose={handleClose} severity={messageInfo?.severity} sx={{ width: '100%' }}>
          {messageInfo?.message}
        </Alert>
      </Snackbar>
      <SnackbarContext.Provider value={showSnackbar}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export default SnackbarProvider;
