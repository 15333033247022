import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Link, useLoaderData } from "react-router-dom";

import Api, { getPeople } from '@/api/admin-api';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity, Person } from '@/constants';
import { hexEncode } from '@/utilities';


export async function loader({ params }: any) {
    const people = await getPeople();
    return { people };
}  

export default function People() {
    const { people }: any = useLoaderData();
    const showSnack = useSnackbar();

    const [peopleState, setPeopleState] = useState<Array<Person>>(people);

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    return (
        <div>
            <h1 className='page-heading'>People</h1>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Last Contacted</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {peopleState.map((person: Person) => (
                            <TableRow
                                key={person.ContactId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {person.Name}
                                </TableCell>
                                <TableCell align="right">
                                    {person.Email}
                                </TableCell>
                                <TableCell align="right">
                                    {person.LastContacted}
                                </TableCell>
                                <TableCell align="right">
                                    <Button color='inherit'><EditTwoToneIcon /></Button>
                                    <Button color='error'><DeleteForeverTwoToneIcon /></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Button><Link to='new'>Add a Contact</Link></Button> */}
        </div>
    )
}