import SaveIcon from '@mui/icons-material/Save';

type Props = {
    text: string
}

export default function SaveBtn(props: Props) {
    const {
        text
    } = props;

    return (
    <button type="submit" id="submit-btn" className='btn btn-primary'>
        <span className="btn-internal">
            <SaveIcon />{text}
        </span>
    </button>
    )
}