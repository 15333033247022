import { BotConfig } from "@/constants";
import { validateDownloadDir } from "@/utilities"

type SaveDirectoryProps = {
    config: BotConfig | null
    setConfig: any
};

export default function SaveDirectory(props: SaveDirectoryProps) {
    const {
        config,
        setConfig
    } = props

    return (
        <div>
            <label className='form-label' htmlFor='downloaddir'>Download Directory</label>
            <input
                id='downloaddir'
                className='form-control'
                type='text' 
                name='downloadpath'
                value={config?.DestPath ?? ''}
                onChange={e => setConfig({...config, DestPath: e.target.value})}
                onBlur = {e => setConfig({...config, DestPath: validateDownloadDir(e.target.value)})}
                placeholder='enter download path'
                required={true}
            />
        </div>
    )
}