import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { Link} from "react-router-dom";

import { Bot } from '@/constants';
import { getScraperCode } from '@/utilities';

function ScraperSelect(props: any) {
  const {
    selectScraper,
    bots,
    botCode
  } = props;

  return (
    <div>
      <label className='form-label' htmlFor='scrapeselect'>Choose a Bot</label>
      <div className='flex-container'>
        <select id='scrapeselect' className='form-select' name="scrapeselect" onChange={selectScraper} value={botCode ? botCode : 0}>
          <option value={0}>Select a bot</option>
          {bots.map((bot: Bot) => <option key={bot.BotId} value={getScraperCode(bot.RunApiEndpoint)}>{bot.Name}</option>)}
        </select>
        <div className='btn-wrapper mybtn'>
          {botCode ? <Link to={`/bots/${botCode}`}><button className='bot-select-option-btn' type='button' title='edit this bot'><EditIcon /></button></Link>
            : <Link to='/bots/new'><button className='bot-select-option-btn' type='button' title='add bot'><AddCircleIcon /></button></Link>}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ScraperSelect)
