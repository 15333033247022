import { faGlobe, faKey, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import PestControlIcon from "@mui/icons-material/PestControl";
import PinDropIcon from '@mui/icons-material/PinDrop';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useAuthContext } from '../Hooks/Auth/AuthHook';

import './nav.css';

export default function AdminMenu() {
  const navigator = useNavigate();
  const { setAuthed } = useAuthContext();

  function handleLogout() {
    document.cookie = 'setsight' + '=; Max-Age=0'
    setAuthed(false)
    return navigator('/')
  }
  
  return (
    <>
      <div id="sidebar">
        <div className='nav-header'>
          <div className='home-btn-container'>
            <Link className='btn-link' to={`/`}><button type='button'>Home</button></Link>
          </div>

          <button type='button' onClick={handleLogout} className='btn-internal'>
            <LogoutIcon color='info'/>
            Logout
          </button>

          <Link className='btn-link' to='http://fluffycloud.setsight.com/html/admin/masteradminhome.htm'>
            <button className='btn-internal' type='button'><AdminPanelSettingsIcon color='info' />Back to Fluffy Admin</button>
          </Link>
        </div>

        <Link className='top-nav-btn' to='/overview'>
          <span className='btn-internal'>
            <QueryStatsIcon  color='info'/>
            Scrapers Overview
          </span>
        </Link>

        <Link className='top-nav-btn' to={`bots/0/configs/0/debug`}>
          <span className='btn-internal'>
            <PestControlIcon color='info'/>Debug/Custom Run Configs
          </span>
        </Link>

        <nav>
          <h4 className='nav-sub-heading'>Bot Configuration</h4>
          <ul>
            <li>
              <Link to={`portals`}><FontAwesomeIcon icon={faGlobe} className='icon' />Websites (portals)</Link>
            </li>
            <li>
              <Link to={`credentials`}><FontAwesomeIcon icon={faKey} className='icon' />Website Credentials</Link>
            </li>
            <li>
              <Link to={`bots`}><SmartToyIcon color='info' />Bots (base scrapers)</Link>
            </li>
            <li>
              <Link to={`destinations`}><FontAwesomeIcon icon={faServer} className='icon' />Server Destination Paths</Link>
            </li>
            <li>
              <Link to={`configs`}><SettingsIcon color='info'/>Configs (aggregated scraper)</Link>
            </li>
          </ul>
          <h4 className='nav-sub-heading'>Info</h4>
          <ul>
            <li>
              <Link to={`people`}><PeopleIcon color='info'/>People</Link>
            </li>
            <li>
              <Link to={`help`}><HelpIcon color='info'/>Help</Link>
            </li>
          </ul>
          <h4 className='nav-sub-heading'>Campaigns</h4>
          <ul>
            <li>
              <Link to={`campaigns`}>Campaigns<CampaignIcon color='info'/></Link>
            </li>
            <li>
              <a href="https://fluffycloud.setsight.com/html/scrape/CampaignInvite.htm">Vericast Campaign Invite Form</a>
            </li>
            <li>
              <Link to={`entry/campaigns`}>Vericast Campaign Entry Form</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}