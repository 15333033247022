import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import Api from "@/api/campaign-api"

export default function TokenLogin() {

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get("token")
        const redirectUrl = searchParams.get("returnurl")
        Api.oauthLogin(token)
            .then((res: any) => {
                document.cookie = `setsight=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${window.location.hostname === "localhost" ? "localhost" : "setsight.com"};`;
                sessionStorage.setItem('ss3apitoken', res.access_token)
                window.location.replace(redirectUrl as string)
            }).catch((err: any) => {
                console.error(err.statusText)
                alert('error logging in')
        });
    }, []);

    // console.log([botId, configId])

    return (
        <div>
            <p>Attempting to login ...</p>
        </div>
    )
}
