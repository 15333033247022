import $ from 'jquery';
import apiServer from "./serverInfo";

const adminapi = {
    // LoggerOutput.js - logs
    pollLog: logpath => {
        return $.ajax({
            url: `${apiServer}api/scrape/polllog`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
            data: {logpath: logpath}
        });
    },

    // metrics api
    getMetric: configId => {
        return $.ajax({
            url: `${apiServer}api/scrape/metrics/${configId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    getMetrics: () => {
        return $.ajax({
            url: `${apiServer}api/scrape/metrics`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    getAreScraping: () => {
        return $.ajax({
            url: `${apiServer}api/scrape/metrics/isscraping`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    getIsScraping: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/metrics/isscraping/${configId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    cancelBot: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/metrics/cancel/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            }
        });
    },

    // configs api
    getBotConfigs: (botId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/configs/botconfigs/${botId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    getDefaultConfigIdByBotId: (botId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/configs/getdefault/${botId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    getIsDefaultConfig: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/configs/${configId}/isdefault`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },

    getConfig: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/configs/${configId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    saveNewConfig: (newConfigDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/configs`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: newConfigDto
        });
    },
    
    // jobs api
    getDefaultBotJob: (botId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/jobs/getdefault/${botId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    getJob: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/jobs/${configId}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },

    // portals api
    getPortalByConfigId: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/portals`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
            data: {configId: configId}
        });
    },
    getPortalByCredId: (credId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/portals`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
            data: {credId: credId}
        });
    },

    // destinations api
    getDestinations: () => {
        return $.ajax({
            url: `${apiServer}api/scrape/dest`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    getDestination: (path) => {
        return $.ajax({
            url: `${apiServer}api/scrape/dest?path=${path}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    postDestination: (dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/dest`,
            type: 'POST',
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        });
    },
    deleteDest: (path) => {
        return $.ajax({
            url: `${apiServer}api/scrape/dest?path=${path}`,
            type: 'DELETE',
            xhrFields: {
                withCredentials: true
            }
        });
    },
    putBotDest: (path, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/dest?path=${path}`,
            type: 'PUT',
            data: dto,
            xhrFields: {
                withCredentials: true
            }
        });
    }
};

// bots api
export const getBots = () => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
    });
}
export const getBotsByPortalId = (portalId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
        data: {portalId: portalId}
    });
}
export const getBotByConfigId = (configId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
        data: {configId: configId}
    });
}
export const getBot = (botId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots/${botId}`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const getBotByBotCode = (botCode) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots/botcode/${botCode}`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const postBot = (dto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots`,
        type: 'POST',
        data: dto,
        xhrFields: {
            withCredentials: true
        }
    });
}
export const putBot = (botId, dto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots/${botId}`,
        type: 'PUT',
        data: dto,
        xhrFields: {
            withCredentials: true
        }
    });
}
// api/scrape/bots/{botId}
export const deleteBot = (botId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/bots/${botId}`,
        type: 'DELETE',
        xhrFields: {
            withCredentials: true
        }
    });
}

// configs api
export const getBotConfigs = (botId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/configs/botconfigs/${botId}`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const getConfigs = () => {
    return $.ajax({
        url: `${apiServer}api/scrape/configs`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const getConfig = (configId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/configs/${configId}`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const deleteConfig = (configId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/configs/${configId}`,
        type: 'DELETE',
        xhrFields: {
            withCredentials: true
        }
    });
}

// creds api
export const getCreds = () => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const getPlainPwd = (credId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds/${credId}/plain`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const getCredByConfigId = (configId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
        data: {configId: configId}
    });
}
export const getPlainCredByConfigId = (configId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds/plain`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
        data: {configId: configId}
    });
}
export const getCredsByPortalId = (portalId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
        data: {portalId: portalId}
    });
}
export const getCred = (credId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds/${credId}`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}
export const deleteCred = (credId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds/${credId}`,
        type: 'DELETE',
        xhrFields: {
            withCredentials: true
        }
    });
}
// add a new bot config cred
export const postNewCred = (dto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds/new`,
        type: 'POST',
        data: dto,
        xhrFields: {
            withCredentials: true
        }
    });
}

export const putBotCred = (credId, dto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/creds/${credId}`,
        type: 'PUT',
        data: dto,
        xhrFields: {
            withCredentials: true
        }
    });
}

// api/scrape/creds/{credId}/status
export const putCredStat = (credId, dto) => {
    // let myData = JSON.stringify(`stat: ${dto}`)
    return $.ajax({
        url: `${apiServer}api/scrape/creds/${credId}/status?stat=${dto}`,
        type: 'PUT',
        xhrFields: {
            withCredentials: true
        }
    });
}

// portals
export const getPortals = () => {
    var token = sessionStorage.getItem('ss3apitoken');
    var headers = {};
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return $.ajax({
        type: 'GET',
        url: apiServer + "api/scrape/portals",
        headers: headers,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const getPortal = (portalId) => {
    return $.ajax({
        type: 'GET',
        url: `${apiServer}api/scrape/portals/${portalId}`,
        xhrFields: {
            withCredentials: true
        }
    })
}


export const putPortal = (portalId, portalDto) => {
    return $.ajax({
        type: 'PUT',
        url: `${apiServer}api/scrape/portals/${portalId}`,
        data: portalDto,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const postPortal = (portalDto) => {
    return $.ajax({
        type: 'POST',
        url: `${apiServer}api/scrape/portals`,
        data: portalDto,
        xhrFields: {
            withCredentials: true
        }
    })
}

export const deletePortal = (portalId) => {
    return $.ajax({
        type: 'DELETE',
        url: `${apiServer}api/scrape/portals/${portalId}`,
        xhrFields: {
            withCredentials: true
        }
    })
}

// putConfig: (configId, configDto) => $.put(apiServer + `api/scrape/configs/${configId}`, configDto),
export const putConfig = (configId, configDto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/configs/${configId}`,
        type: 'PUT',
        data: configDto,
        xhrFields: {
            withCredentials: true
        }
    });
}

// jobs api
export const getAllJobs = () => {
    return $.ajax({
        url: `${apiServer}api/scrape/jobs`,
        type: 'GET',
        xhrFields: {
            withCredentials: true
        }
    });
}

// PUT
export const putJob = (configId, jobDto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/jobs/${configId}`,
        type: 'PUT',
        data: jobDto,
        xhrFields: {
            withCredentials: true
        }
    });
}
// POST
export const postJob = (configId, jobDto) => {
    return $.ajax({
        url: `${apiServer}api/scrape/jobs/${configId}`,
        type: 'POST',
        xhrFields: {
            withCredentials: true
        },
        data: jobDto
    });
}
// DELETE
export const deleteJob = (configId) => {
    return $.ajax({
        url: `${apiServer}api/scrape/jobs/${configId}`,
        type: 'DELETE',
        xhrFields: {
            withCredentials: true
        }
    });
}

// people
export const getPeople = () => {
    return $.ajax({
        url: `${apiServer}api/scrape/contacts`,
        type: 'GET',
        // success: function (result) {
        //     // Do something with the result
        // }
        xhrFields: {
            withCredentials: true
        }
    });
}

// auth
export const ss3Login = (dto) => {
    return $.ajax({
        url: `${apiServer}api/auth`,
        type: 'POST',
        data: dto,
        // success: function (result) {
        //     // Do something with the result
        // }
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        }
    });
}

export const authorize = () => {
    return $.ajax({
        url: `${apiServer}api/authorize`,
        type: 'GET',
        // success: function (result) {
        //     // Do something with the result
        // }
        xhrFields: {
            withCredentials: true
        }
    });
}

export default adminapi;