import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { IconButton } from '@mui/material';
import { Link, useLocation } from "react-router-dom";

import ScrapeJob from "@/components/ScraperForm/ScrapeJob";
import { Job } from "@/constants";

type JobFieldProps = {
    job: Job | null
};

export default function JobField(props: JobFieldProps) {
    const {
        job,
    } = props

    const location = useLocation();

    // remove edit from url when moving to job
    const urlPath = location.pathname
    let toPart = urlPath.replace('/edit', '');
    if (toPart.at(-1) === '/')
        toPart += 'job'
    else
        toPart += '/job'

    return (
        <fieldset className="form-group border p-3">
            <div className="entity-header">
                <legend>Config Job</legend>
                {job ? <Link to={toPart}><IconButton><EditTwoToneIcon /></IconButton></Link> :<Link to={`${toPart}/new`}> <IconButton><LibraryAddIcon /></IconButton></Link>}
            </div>
            {job && 
                <div>
                    <div className='text-input-label-group'>
                        <label className='text-label'>Job Name:</label>
                        {job?.Name}
                    </div>
                    <div className='text-input-label-group'>
                        <label className='text-label'>Job Description:</label>
                        {job?.Description}
                    </div>
                    <ScrapeJob disabled={true} job={job} setJob={undefined} placeHolder='n/a' />
                </div>
            }
        </fieldset>
    )
}
