import DeleteForever from '@mui/icons-material/DeleteForever'

type Props = {
    handleDelete: any;
    text: string
}
export default function DeleteBtn(props: Props) {

    const { handleDelete, text } = props

    return (
        <button type='button' onClick={handleDelete} className='btn btn-danger'>
            <span className='btn-internal'>
                <DeleteForever />{text}
            </span>
        </button>
    )
}