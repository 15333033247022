import CancelIcon from '@mui/icons-material/Cancel';
import { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Api from '@/api/admin-api';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity } from "@/constants";

type CancelBtnProps = {
    configId: number | undefined
    isScraping: boolean
}

export default function CancelBtn(props: CancelBtnProps) {
    const { configId, isScraping } = props;

    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#C4BBB8',
        }
    });

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    function handleCancel() {
        if (configId)
            Api.cancelBot(configId).then(() => {
                handleShowSnackBar('Requested cancellation.', SnackbarSeverity.SUCCESS)
            }).catch((err: any) => {
                console.error(err)
                handleShowSnackBar(`Cancel request failed.`, SnackbarSeverity.ERROR)
            })
        else
            handleShowSnackBar('Config not selected.', SnackbarSeverity.WARNING)
    }
    
    return (
        <BootstrapButton className='btn-wrapper' onClick={handleCancel} disabled={!isScraping} color='error'>
            <span className='btn-internal'>
                <CancelIcon /><span>Cancel</span>
            </span>
        </BootstrapButton>
    )
}