import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useEffect, useState } from 'react';
import { Link, useLoaderData } from "react-router-dom";

import Api, { getConfig, getBotByConfigId, getCredByConfigId } from "@/api/admin-api"
import ConfigFields from '@/components/FieldSet/ConfigFields';
import CredField from '@/components/FieldSet/CredField';
import JobField from '@/components/FieldSet/JobField';
import { Credential, Portal, Bot, Job, BotConfig } from '@/constants';
import './configs.scss'

export async function loader({ params }: any) {
    const config: BotConfig = await getConfig(params.configId);
    const portal: Portal = await Api.getPortalByConfigId(params.configId);
    const isDefaultConfig = await Api.getIsDefaultConfig(params.configId);
    const bot: Bot = await getBotByConfigId(params.configId)
    const cred: Credential = await getCredByConfigId(params.configId)
    return { portal, config, isDefaultConfig, bot, cred };
}

export default function ViewConfig() {
    const { portal, config, isDefaultConfig, bot, cred }: any = useLoaderData();

    const [job, setConfigJob] = useState<any>()

    useEffect(() => {
        Api.getJob(config.ConfigId).then((resp: Job) => {
            setConfigJob(resp)
        }).catch((resp: any) => {
            if (resp.status === 404)
                console.warn('no job associated with this config')
            else
                console.error('error retreiving a config job')
        })
    }, []);

    return (
        <div className='route-container'>
            <div className='entity-header'>
                <h1 className='page-heading'>&quot;{config.Name}&quot; Bot Config</h1>
                <Link to="edit"><button type="button">{<EditTwoToneIcon />}</button></Link>
            </div>
            <ConfigFields config={config} isDefault={isDefaultConfig.IsDefaultConfig} portal={portal} bot={bot} />
            <CredField cred={cred} />
            <JobField job={job} />
        </div>
    )
}
