import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SaveIcon from '@mui/icons-material/Save';
import { AlertColor } from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { useLoaderData } from "react-router-dom";

import Api, { getConfig, putJob, getBotByConfigId } from "@/api/admin-api";
import { getClientShortCodesByIngestionType, getClientShortCoes } from '@/api/data_setup-api';
import ScraperApi from "@/api/scraper-api";
import EditConfigJobModal from '@/components/FieldSet/EditJobModal';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeJob from "@/components/ScraperForm/ScrapeJob";
import DataTable from '@/components/Table/DataTable';
import { SnackbarSeverity, POLVdsIds, BotConfig, Job, UpdateJobDto, Bot } from '@/constants';
import { getScraperCode } from '@/utilities';

export async function loader({ params }: any) {
    const config: BotConfig = await getConfig(params.configId);
    const job: Job = await Api.getJob(params.configId);
    const bot: Bot = await getBotByConfigId(params.configId);
    const botcode = getScraperCode(bot.RunApiEndpoint);
    let ingestionType;
    switch (botcode) {
        case 'ipc':
            ingestionType = 'TargetIPC'
            break;
        default:
            ingestionType = ''
    }
    return { config, job, ingestionType };
}

export default function ConfigJobEditor() {

    const { job, config, ingestionType }: any = useLoaderData();

    const [jobState, setJob] = useState<Job>(job)
    const [polVds, setPolVds] = useState<Array<POLVdsIds>>([])
    const [vdsLoading, setVdsLoading] = useState<boolean>(false)
    const [clients, setClients] = useState<Array<string>>([])

    const [reasonText, setReasonText] = useState<string>('')

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    useEffect(() => {
        handleGetPolVdsIds(config.ConfigId)
        if (ingestionType)
            getClientShortCodesByIngestionType(ingestionType).then((res: Array<string>) => {
                setClients(res);
            }).catch(() => {
                setClients([])
            })
        else {
            getClientShortCoes().then((res: Array<string>) => {
                setClients(res);
            }).catch(() => {
                setClients([])
            })
        }
    }, [])
    
    function handleUpdateJob(e: any) {
        e.preventDefault();
        const updateJobPayload: UpdateJobDto = {
            ConfigId: job.ConfigId,
            Name: jobState.Name,
            Description: jobState.Description,
            JobString: jobState.JobString,
            ModifiedReason: reasonText
        }
        putJob(config.ConfigId, updateJobPayload)
            .then(() => {
                handleClose()
                handleShowSnackBar('Successfully updated the config job.', SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleClose()
                handleShowSnackBar('Error updating the config job.', SnackbarSeverity.ERROR);
                console.error(error.responseText)
        });
    }

    function handleGetPolVdsIds(configId: number, clearCache = false) {
        setVdsLoading(true)
        ScraperApi.getPolVds(configId, clearCache).then((resp: Array<POLVdsIds>) => {
            setVdsLoading(false)
            setPolVds(resp)
        }).catch(() => {
            setVdsLoading(false)
            handleShowSnackBar(`Error getting VDS data.`, SnackbarSeverity.ERROR);
        })
    }

    function parseJobState() {
        try {
            let jobstrjson = JSON.parse(jobState.JobString);
            if(Object.keys(jobstrjson).includes('job'))
                jobstrjson = jobstrjson['job']
            return jobstrjson;
        } catch {
            console.error('error')
            return '{}'
        }
    }

    function setParsedJob(val: any) {
        try {
            const jobstrjson = JSON.parse(jobState.JobString);
            if(Object.keys(jobstrjson).includes('job')) {
                jobstrjson['job'] = JSON.parse(val.JobString)
                setJob({...jobState, JobString: JSON.stringify(jobstrjson)})
            }
            else {
                setJob({...jobState, JobString: val.JobString})
            }
        } catch {
            console.error('something didn\'t work')
        }
    }

    return (
        <div>
            <h1 className='page-heading'>{config.Name} Job Editor</h1>
            <form onSubmit={handleUpdateJob}>
                <ScrapeJob disabled={false} job={jobState} setJob={setJob} placeHolder='n/a' />
                <div className='flex-container' id='job-editor-buttons'>
                    <button type="button" id="submit-btn" className='btn btn-primary' onClick={handleOpen}>
                        <span className="btn-internal">
                            <SaveIcon />Save Changes
                        </span>
                    </button>
                    <button type='button' className='btn btn-secondary' disabled={vdsLoading} onClick={() => handleGetPolVdsIds(config.ConfigId, true)}>
                        <span className='btn-internal'><FontAwesomeIcon icon={faRepeat}/>Refresh table data from POL</span>
                    </button>
                </div>
                <DataTable loading={vdsLoading} polvds={polVds} jobstate={{JobString: JSON.stringify(parseJobState())} as Job} setjob={setParsedJob} clients={clients} />
                <div>
                    <input
                        type="text"
                        id="jobname"
                        name='name'
                        hidden
                        value={config.Name}
                        readOnly
                    />
                    <input
                        type="textarea"
                        id="jobdescription"
                        name='description'
                        hidden
                        value={config.Description}
                        readOnly
                    />
                </div>
                <EditConfigJobModal isOpen={open} setClose={handleClose} handleUpdateJob={handleUpdateJob} reasonText={reasonText} setReason={setReasonText} />
            </form>
        </div>
    )
}
