import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { AlertColor } from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import { useState, useEffect } from 'react';

import SaveBtn from '../Buttons/SaveBtn';

import { getPlainPwd, putCredStat, putBotCred } from "@/api/admin-api"
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Credential, SnackbarSeverity } from '@/constants';
import { copyToClipboard } from '@/utilities';

import './fieldset.scss'
import './cred-style.scss'

type CredFieldProps = {
    cred: Credential
}

export default function CredField(props: CredFieldProps) {
    const {
        cred
    } = props;

    useEffect(() => {
        if(openPutCred)
            getPlainPwd(cred.CredId).then((resp: any) => {
                setPassword(resp.Password)
                setIsDecrypted(true)
            }).catch(() => {
                console.error('error decrypting password')
            })
    }, []);

    const [password, setPassword] = useState<string>(cred.Password)
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [username, setUsername] = useState<string>(cred.Username)

    const [isDecrypted, setIsDecrypted] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(cred.Status)

    const [mfaType, setMfaType] = useState<string>(cred.MFAType)

    const [openPutCred, setOpenPutCred] = useState<boolean>(false)

    const [selectedPortalId, setSelectedPortalId] = useState<number>(0)

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };
    
    function handleDecryptPwd() {
        if (isDecrypted) {
            copyToClipboard(password)
            handleShowSnackBar(`Copied the plaintext password.`, SnackbarSeverity.SUCCESS);
            return;
        } else {
            getPlainPwd(cred.CredId).then((resp: any) => {
                setPassword(resp.Password)
                setIsDecrypted(true)
                copyToClipboard(resp.Password)
                handleShowSnackBar(`Copied the plaintext password to the clipboard.`, SnackbarSeverity.SUCCESS);
            }).catch(() => {
                handleShowSnackBar(`Failed to decrypt the password.`, SnackbarSeverity.ERROR);
            })
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        putCredStat(cred.CredId, event.target.checked).then((resp: any) => {
            setStatus(!event.target.checked);
            handleShowSnackBar(`Successfully updated the cred status.`, SnackbarSeverity.SUCCESS);
        }).catch((err: any) => {
            handleShowSnackBar(`Error updating the cred status.`, SnackbarSeverity.ERROR);
        })
    };

    function handleCredUpdate(e: any) {
        e.preventDefault();
        const formJson = Object.fromEntries(new FormData(e.target).entries());
        if (formJson.confirmPassword != formJson.password) {
            handleShowSnackBar(`Passwords do not match.`, SnackbarSeverity.ERROR);
            return
        }
        formJson.portalId = JSON.stringify(selectedPortalId)
        formJson.credId = JSON.stringify(cred.CredId)
        putBotCred(cred.CredId, formJson)
            .then((res: any) => {
                setOpenPutCred(false)
                handleShowSnackBar(`Successfully updated the credential.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Failed to update the credential.`, SnackbarSeverity.ERROR);
        });
    }

    function handleOpenPutCred() {
        setOpenPutCred(!openPutCred)
        setPassword('')
        setConfirmPassword('')
    }

    function handleCloseEdit() {
        setOpenPutCred(false)
        setPassword(cred.Password)
    }

    return (
        <fieldset className="form-group border p-3 fieldset-center">
            <div className='entity-header'>
                <legend>Credential</legend>
                <span className='mx-2'>
                    {!openPutCred && <button id='edit-cred' type="button" onClick={handleOpenPutCred}>{<EditTwoToneIcon />}</button>}
                    { openPutCred && <button type="button" onClick={handleCloseEdit}>{<VisibilityTwoToneIcon />}</button> }
                </span>
            </div>
            <form onSubmit={handleCredUpdate} className='fieldset-form' id='cred-form'>
                <div className='cred-container'>
                    <label className='input-label'>
                        Username:
                        <input 
                            type='text'
                            name='username'
                            className='input-field form-control'
                            disabled={!openPutCred}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required={true}
                        />
                        {!openPutCred && <span className='copy-btn' onClick={() => {
                            handleShowSnackBar(`Copied the username to the clipboard.`, SnackbarSeverity.SUCCESS);
                            copyToClipboard(username)
                        }}><button title='copy username' type='button'><ContentCopyIcon /></button></span>}
                    </label>
                    <label className='input-label'>
                        {openPutCred ? 'New Password' : 'Password'}
                        <input
                            type={isDecrypted && !openPutCred ? 'text' : 'password'}
                            name='password'
                            className='input-field form-control'
                            value={password}
                            disabled={!openPutCred}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                        />
                        {!openPutCred && <span className='copy-btn' onClick={handleDecryptPwd}><button title='copy password' type='button'><ContentCopyIcon /></button></span>}
                    </label>
                    { openPutCred &&
                    <label className='input-label'>
                        Confirm Password:
                        <input 
                            type='password'
                            name='confirmPassword'
                            className='input-field'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required={true}
                        />
                    </label>
                    }
                <div className='cred-input-group'>
                    <label className='input-label'>Multi-Factor-Auth Type:
                        <select
                            id='mfatypeselect'
                            name="mfatype"
                            className='form-select input-field'
                            value={mfaType}
                            onChange={(e) => setMfaType(e.target.value)}
                            disabled={!openPutCred}
                        >
                            <option value='None'>None</option>
                            <option value="SMS">SMS</option>
                            <option value="Email">Email</option>
                            <option value="Security Question">Security Question</option>
                            <option value="Authenticator App">Authenticator App</option>
                        </select>
                    </label>
                </div>

                <div>{openPutCred && <SaveBtn text='Save' />}</div>
                
                <label className='input-label'>Status:
                    <div>
                        <WarningRoundedIcon />
                        <Switch
                            checked={status}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            name='status'
                        />
                        <CheckRoundedIcon />
                    </div>
                </label>
                </div>
            </form>
        </fieldset>
    )
}