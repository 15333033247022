type ScraperHeaderProps = {
    name: string,
    description: string
}
export default function ScraperHeader(props: ScraperHeaderProps) {
    const {
        name,
        description
    } = props

    return (
        <>
            <h2>{name} Bot</h2>
            <h5>{description}</h5>
        </>
    )
}