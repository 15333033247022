import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { useLoaderData } from "react-router-dom";

import { getCampaign, getCampaignPortals, getCredCreatorAuth } from "@/api/campaign-api";
import Api from "@/api/campaign-api"
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Portal, SnackbarSeverity } from '@/constants';

import "@/routes/Campaign/campaigns.scss"

export async function loader({ params }: any) {
    await getCredCreatorAuth();
    const campaign = await getCampaign(params.campaignId);
    const portals = await getCampaignPortals(params.campaignId);
    return { campaign, portals };
}

export default function CampaignCredentialsEntry() {
    const { campaign }: any = useLoaderData();
    const { portals }: any = useLoaderData();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [mfaType, setMfaType] = useState<string>('None');

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    function handleSavePortalCred(portalId: number) {
        // Prevent the browser from reloading the page
        // e.preventDefault();

        // const formJson = Object.fromEntries(new FormData(e.target).entries());
        // formJson.PortalId = e.target.name
        const myCredDto = {
            portalId: portalId,
            campaignId: campaign.CampaignId,
            username: username,
            password: password,
            mfaType: mfaType
        }
        if(portalId === 0)
            handleShowSnackBar(`Unexpected error.`, SnackbarSeverity.ERROR);
        // e.outertext
        if (myCredDto.username === '')
            handleShowSnackBar(`Username can't be empty.`, SnackbarSeverity.WARNING);
        else if (myCredDto.password === '')
            handleShowSnackBar(`Password can't be empty.`, SnackbarSeverity.WARNING);
        else {
            Api.savePortalCred(myCredDto)
                .then((data: Credential, textStatus: string, xhr: any) => {
                    if(xhr.status === 201)
                        handleShowSnackBar(`New credential saved.`, SnackbarSeverity.SUCCESS);
                    else if(xhr.status === 200) {
                        handleShowSnackBar(`Credential already exists.`, SnackbarSeverity.INFO);
                    }
                }).catch((err: any, textStatus: string, xhr: any) => {
                    console.error(textStatus)
                    handleShowSnackBar(`Error saving portal credential: ${textStatus}`, SnackbarSeverity.ERROR);
            });
            setUsername('')
            setPassword('')
            setMfaType('None')
        }
    }

    function handleSaveMiscCred(e: any) {
        e.preventDefault();
        const myCredDto = {
            campaignId: campaign.CampaignId,
            username: username,
            password: password,
            mfaType: mfaType
        }
        if (myCredDto.username === '')
        handleShowSnackBar(`Username can't be empty.`, SnackbarSeverity.WARNING);
        else if (myCredDto.password === '')
            handleShowSnackBar(`Password can't be empty.`, SnackbarSeverity.WARNING);
        else {
            // api/scrape/creds/camp/misc
            Api.saveMiscCampCred(myCredDto)
                .then((data: Credential, textStatus: string, xhr: any) => {
                    if(xhr.status === 201)
                        handleShowSnackBar(`New credential saved.`, SnackbarSeverity.SUCCESS);
                    else if(xhr.status === 200) {
                        handleShowSnackBar(`Credential already exists.`, SnackbarSeverity.INFO);
                    }
                }).catch((err: any, textStatus: string, xhr: any) => {
                    console.error(textStatus)
                    handleShowSnackBar(`Error saving campaign credential: ${textStatus}`, SnackbarSeverity.ERROR);
            });
            setUsername('')
            setPassword('')
            setMfaType('None')
        }
    }

    function handleLogout() {
        sessionStorage.removeItem('ss3apitoken')
        window.location.replace('https://setsight.com/')
    }
    
    return (
        <div id='camp-entry'>
            <div id='campaign-entry-container'>
                <div className='campaign-entry-header'>
                    <div>
                        <div className="heading">&quot;{(campaign.Name).toUpperCase()}&quot; CREDENTIAL ENTRY FORM</div>
                        <div className="subheading">{campaign.Description}</div>
                    </div>
                    <img src="../../HTML/images/logo_vericast.png" height={25} alt="logo"/>
                    <button type="button" className='btn btn-light logout-btn' onClick={handleLogout}>Logout</button>
                </div>

                <form>
                    <div id='campaign-entry-form'>
                        <fieldset className="form-group border p-3">
                            <legend>Username/Password Entry</legend>
                            <div className="text-input-label-group">
                                <label className='text-label' htmlFor="dataCampaignName">Login Username</label>
                                <input
                                    type="text"
                                    id="dataUsername"
                                    name='username'
                                    value={username}
                                    placeholder="the campaign portal username"
                                    tabIndex={10}
                                    autoFocus
                                    required={true}
                                    onChange={e => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="text-input-label-group">
                                <label className='text-label' htmlFor="dataCampaignDescription">Password</label>
                                <input
                                    type="password"
                                    id="dataPassword"
                                    name='password'
                                    value={password}
                                    placeholder="the campaign portal password"
                                    tabIndex={10}
                                    required={true}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                        </fieldset>

                        <fieldset className="form-group border p-3">
                            <legend>Multifactor Authentication Selection</legend>
                            <div className='select-container'>
                                <select title='mfa-select' className='form-select' id='mfatypeselect' name="mfatype" onChange={e => setMfaType(e.target.value)} value={mfaType}>
                                    <option value='None'>None</option>
                                    <option value='SMS'>SMS</option>
                                    <option value='Email'>Email</option>
                                    <option value='Security Question'>Security Question</option>
                                    <option value='Authenticator App'>Authenticator App</option>
                                </select>
                            </div>
                        </fieldset>

                        <div>
                            <ul id="save-cred-btns">
                                {portals.length > 0 ? 
                                    portals.map((portal: Portal, i: number) => 
                                        <li key={portal?.PortalId}>
                                            <button 
                                                id={`save-portal-cred-btn-${i}}`} 
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    handleSavePortalCred(portal.PortalId)
                                                    }
                                                }
                                            >Save the &quot;<em>{portal?.Name}</em>&quot; portal credential
                                            </button>
                                        </li>
                                    )
                                :
                                <li>
                                    <button id='misc-cred-submit' type="button" onClick={handleSaveMiscCred}>Save the &quot;<em>{campaign.Name}</em>&quot; campaign credential</button>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
