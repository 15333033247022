// import { useState } from 'react';

import { Credential } from "@/constants";

type UsernamePasswordProps = {
    cred: Credential | null,
    setCred: any,
};

export default function UsernamePassword(props: UsernamePasswordProps) {
    const {
        cred,
        setCred
    } = props;

    return (
        <>
            <div>
                <label className='form-label' htmlFor='username'>Username</label>
                <input
                    id='username'
                    className='form-control'
                    type='text' 
                    name='username'
                    value={cred?.Username ?? ''}
                    onChange={e => setCred({...cred, Username: e.target.value})}
                    placeholder='enter username'
                    required={true}
                />
            </div>
            <div>
                <label className='form-label' htmlFor='password'>Password</label>
                <input
                    id='password'
                    className='form-control'
                    type='password' 
                    name='password'
                    value={cred?.Password ?? ''}
                    onChange={e => setCred({...cred, Password: e.target.value})}
                    placeholder='enter password'
                    required={true}
                />
            </div>
        </>
    );
}