import { useLoaderData } from "react-router-dom";

import Credentials from "../Credentials/Credentials";

import { getCredsByPortalId, getPortal } from "@/api/admin-api";
import { Portal, Credential } from "@/constants";


export async function loader({ params }: any) {
    const portal: Portal = await getPortal(params.portalId);
    const creds: Array<Credential> = await getCredsByPortalId(params.portalId);
    return { portal, creds };
}

export default function PortalForm() {

    const { portal }: any = useLoaderData();

    return (
    <div>
        <h1 className='page-heading'>{portal?.Name}</h1>
            <fieldset className="form-group border p-3">
                <legend>Bot Info</legend>
                <div>
                    <div className='text-input-label-group'>
                        <label htmlFor="name" className='text-label'>Name:</label>
                        <div>{portal?.Name}</div>
                    </div>
                    <div className='text-input-label-group'>
                        <label htmlFor="description" className='text-label'>Description:</label>
                        <div>{portal?.Description}</div>
                    </div>
                    <div className='text-input-label-group'>
                        <label htmlFor="RunApiEndpoint" className='text-label'>Domain:</label>
                        <div>{portal?.Domain}</div>

                    </div>
                </div>
            </fieldset>
        <div>
            <Credentials />
        </div>
    </div>
    )
}