import parser from 'cron-parser'
import cronstrue from 'cronstrue';
import React from "react";

type Props = {
    cronTab: string
    isEdit: boolean
}

export default function Schedule(props: Props) {

    const { cronTab, isEdit } = props;

    const [edit, setEdit] = React.useState(false)
    const [cronTabState, setCronTab] = React.useState(cronTab)
    const [parsedCron, setParsedCron] = React.useState('')

    function parse() {
        try {
            if(cronTabState !== 'None') {
                const interval = parser.parseExpression(cronTabState);
                return <div>{new Date(interval.next().toString()).toLocaleString()}</div>
            }
          } catch (err: any) {
            console.log('Error: ' + err.message);
          }
    }

    function handleParseCron(e: any) {
        try {
            setCronTab(e.target.value)
            setParsedCron(cronstrue.toString(e.target.value))
        } catch (err: any) {
            setParsedCron('Cron tab parse error.')
        }
    }

    return (
        <fieldset className="form-group border p-3">
            <div className='entity-header'>
                <legend>Schedule</legend>
                    {!edit && isEdit ? <button type="button" onClick={() => {
                        setEdit(true)
                        if (cronTabState != 'None')
                            setParsedCron(cronstrue.toString(cronTabState))
                    }}>Edit</button> : isEdit && <button type="button" onClick={() => setEdit(false)}>View</button>}
            </div>
            <div>
                <div className='text-input-label-group'>
                    <label htmlFor="crontab" className='text-label'>CronTab:</label>
                    { edit && <div>
                        <input type='text' id='crontab' value={cronTabState} onChange={handleParseCron} name='crontab' />
                        <div>{parsedCron}</div>
                        <a href='https://crontab.guru/' target="_blank" rel="noreferrer">help constructing crontab</a>
                    </div> }
                    { !edit && <div>{cronTabState != 'None' ? cronstrue.toString(cronTabState) : 'None'}</div> }
                </div>
                <div className='text-input-label-group'>
                    <label htmlFor="crontab" className='text-label'>Next Start:</label>
                    {parse()}
                </div>
            </div>
        </fieldset>
    )
}
