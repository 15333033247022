import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type JobModalProps = {
    isOpen: boolean
    setClose: any
    handleUpdateJob: any
    reasonText: string
    setReason: any
  };

export default function EditConfigJobModal(props: JobModalProps) {
    const {
        isOpen,
        setClose,
        handleUpdateJob,
        reasonText,
        setReason
    } = props;

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={setClose}
        aria-labelledby="edit job reason"
        aria-describedby="place to provide information regarding reason for job modification"
      >
        <Box sx={style}>
            <p>Please provide a short description for the job string update.</p>
            <label className='form-label' htmlFor='modifiedreason'>Modified Reason:</label>
                <textarea className="form-control" name='modifiedreason' id='modifiedreason' rows={3} onChange={(e) => setReason(e.target.value)} value={reasonText} />
            <button className='btn btn-primary' type='submit' onClick={handleUpdateJob}>Submit</button>
        </Box>
      </Modal>
    </div>
  );
}