import StartIcon from '@mui/icons-material/Start';
import Button from '@mui/material/Button';

type ScrapeButtonProps = {
    isScraping: boolean,
};

export default function ScrapeButton(props: ScrapeButtonProps) {
    const {
        isScraping
    } = props

    return (
        <div className='btn-wrapper'>
            <Button
                id='scrape-btn'
                type='submit'
                name='submit'
                disabled={isScraping}
                variant='outlined'
            >
                <span className='btn-internal'>
                    <StartIcon /><span>Scrape</span>
                </span>
            </Button>
        </div>
    )
}