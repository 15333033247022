import { AlertColor } from '@mui/material/Alert';
import React, { useState, useEffect } from 'react';

import Api from "@/api/admin-api";
import ScraperApi from "@/api/scraper-api";
import LoadingSubmitBtn from '@/components/Buttons/LoadingSubmitBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeMetrics from "@/components/Metrics"
import ScraperHeader from '@/components/ScraperForm/Header';
import SaveDirectory from "@/components/ScraperForm/SaveDirectory"
import ScrapeJob from "@/components/ScraperForm/ScrapeJob"
import UsernamePassword from "@/components/ScraperForm/UsernamePassword"
import WgOtpModal from '@/components/ScraperForm/WgOtpModal';
// utility
import { SnackbarSeverity, WgRunBot, WgLoginResp, ScraperProps } from "@/constants";

function WgScraper(props: ScraperProps) {
  const {
    botCode,
    name,
    description,
    config,
    setConfigState,
    cred,
    setCredState,
    job,
    setJobState
  } = props;

  // state
  const [isScraping, setIsScraping] = useState<boolean>(false);
  
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  
  // poll isscraping
  useEffect(() => {
    // make initial api call
    Api.getIsScraping(config?.ConfigId)
      .then((res: any) => {
          setIsScraping(res.IsScraping)
      }).fail(() => {
          setIsScraping(false)
      });
    // if a config is selected poll api every 30 seconds unless not scraping
    if (config) {
      const isScrapingInterval = setInterval(() => {
        Api.getIsScraping(config?.ConfigId)
          .then((res: any) => {
              setIsScraping(res.IsScraping)
              if (!res.IsScraping) {
                  clearInterval(isScrapingInterval)
              }
          }).fail(() => {
              clearInterval(isScrapingInterval)
              setIsScraping(false)
          });
      }, 30000);
      return () => {
          clearInterval(isScrapingInterval)
      };
    }
  }, [isScraping, config?.ConfigId]);

  const showSnack = useSnackbar();

  const handleShowSnackBar = (message: string, severity: AlertColor) => {
    showSnack(message, severity);
  };

  function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    setIsLoading(true)
    // Read the form data as json
    const formJson = Object.fromEntries(new FormData(e.target).entries());
    // { CsrfToken = csrfToken, FormAction = formAction, SessionCookie = sessionCookie, PfCookie = pfCookie }
    if (config?.ConfigId && config.ConfigId > 0) {
      handleShowSnackBar(`Logging into Walgreens.`, SnackbarSeverity.INFO);
      ScraperApi.wgCustomLogin(config?.ConfigId, formJson)
        .then((resp: WgLoginResp) => {
          setIsLoading(false)
          localStorage.setItem("CsrfToken", resp.CsrfToken);
          localStorage.setItem("FormAction", resp.FormAction);
          localStorage.setItem("SessionCookie", resp.SessionCookie);
          localStorage.setItem("PfCookie", resp.PfCookie);
          handleShowSnackBar(`Sent OTP email.`, SnackbarSeverity.SUCCESS);
          setOpenOtpModal(true)
        }).catch(() => {
          setIsLoading(false)
          handleShowSnackBar(`Failed logging into Walgreens.`, SnackbarSeverity.ERROR);
      });
    } else {
      handleShowSnackBar( `First create a config?.`, SnackbarSeverity.INFO);
    }
  }

  function handleSendOtp(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data as json
    const wgRunPayload: WgRunBot = {
      Otp: otp.trim(),
      CsrfToken: localStorage.getItem("CsrfToken") as string,
      FormAction: localStorage.getItem("FormAction") as string,
      SessionCookie: localStorage.getItem("SessionCookie") as string,
      PfCookie: localStorage.getItem("PfCookie") as string,
      SupplierIds: job?.JobString,
      DownloadPath: config?.DestPath
    }

    if (config?.ConfigId && config.ConfigId > 0) {
      ScraperApi.wgCustomSun(config?.ConfigId, wgRunPayload)
        .then(() => {
          setIsScraping(true)
          setOpenOtpModal(false)
          handleShowSnackBar(`Started scraping Walgreens.`, SnackbarSeverity.INFO);
        }).catch(() => {
          handleShowSnackBar(`Error logging into Walgreens.`, SnackbarSeverity.ERROR);
      }).always(() => setOtp(''));
    } else {
      handleShowSnackBar(`First create a config.`, SnackbarSeverity.INFO);
    }
  }

  return (
    <div className='form-container'>
      <ScraperHeader name={name} description={description} />
      <form method="post" onSubmit={handleSubmit}>
        <UsernamePassword cred={cred} setCred={setCredState} />
        <SaveDirectory config={config} setConfig={setConfigState} />
        <ScrapeJob disabled={false} job={job} setJob={setJobState} placeHolder='["supplier-id","supplier-id","supplier-id"...]' />
        <div className='submit-group'>
          <LoadingSubmitBtn isLoading={isLoading} isScrapign={isScraping} />
          <WgOtpModal open={openOtpModal} setOpen={setOpenOtpModal} sendOtp={handleSendOtp} otp={otp} setOtp={setOtp} isScraping={isScraping} />
        </div>
      </form>
      <ScrapeMetrics config={config} isScraping={isScraping} botCode={botCode} />
    </div>
  )
}

export default React.memo(WgScraper)
