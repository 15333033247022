import { AlertColor } from '@mui/material/Alert';
import { useLoaderData } from "react-router-dom";

import { getPortals, postNewCred } from "@/api/admin-api"
import SaveNewBtn from '@/components/Buttons/SaveNewBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Credential, Portal, SnackbarSeverity } from '@/constants';


export async function loader() {
    const portals = await getPortals();
    return { portals };
}  

export default function NewCredForm() {
    const { portals }: any = useLoaderData();

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };  

    function saveCred(e: any) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        const formJson = Object.fromEntries(new FormData(e.target).entries());
        if (formJson.password != formJson.confirmpassword) {
            handleShowSnackBar("Passwords do not match.", SnackbarSeverity.ERROR);
            return
        }

        postNewCred(formJson)
            .then((res: Credential) => {
                console.log(res)
                handleShowSnackBar("Successfully saved new cred.", SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                console.error(error.responseText)
                handleShowSnackBar(`Error saving new cred: ${error.responseText}`, SnackbarSeverity.ERROR);
        });
    }

    async function handleSelectPortal(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
    }

    return (
        <div>
            <h1 className='page-heading'>New Credential Form</h1>
            <div>
                <form onSubmit={saveCred}>
                    <div>
                        <fieldset className="form-group border p-3">
                            <legend>Cred Details Entry</legend>
                            <div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="username" className='text-label'>Username</label>
                                    <input
                                        type="text"
                                        id="username"
                                        name='username'
                                        placeholder="username"
                                        autoFocus
                                        required={true}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="password" className='text-label'>Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name='password'
                                        placeholder="password"
                                        required={true}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="confirmpassword" className='text-label'>Retype Password</label>
                                    <input
                                        type="password"
                                        id="confirmpassword"
                                        name='confirmpassword'
                                        placeholder="retype password"
                                        required={true}
                                    />
                                </div>

                                <div className='text-input-label-group'>
                                    <label htmlFor="mfatypeselect">MFA Type</label>
                                    <select id='mfatypeselect' name="mfatype" className='form-select'>
                                        <option value='None'>None</option>
                                        <option value="SMS">SMS</option>
                                        <option value="Email">Email</option>
                                        <option value="Security Question">Security Question</option>
                                        <option value="Authenticator App">Authenticator App</option>
                                    </select>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="form-group border p-3">
                            <legend>Portal Select</legend>
                            <div className='text-input-label-group'>
                                <label htmlFor="portals">Portal Option</label>
                                <select name="portalid" title='portals' id="portals" onChange={handleSelectPortal} className='form-select'>
                                    <option value={0}>Choose a portal</option>
                                    {
                                        portals.map((portal: Portal, i: number) => {
                                            return <option key={i} value={portal.PortalId}>{portal.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </fieldset>

                    </div>
                    <SaveNewBtn text='Save New Cred' />
                </form>
            </div>
        </div>
    )
}
