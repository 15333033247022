import { Portal } from "@/constants"

type Props = {
    selectedPortalId: number
    portals: Array<Portal>
}

export default function PortalFieldSet(props: Props) {

    const { selectedPortalId, portals } = props;

    return (
        <fieldset className="form-group border p-3">
            <legend>Portal</legend>
            <div className='text-input-label-group'>
                <label htmlFor="portals">Cred Portal</label>
                <select
                    name="portalid"
                    title='portals'
                    id="portals"
                    className='form-select'
                    value={selectedPortalId}
                    // cred portal can't be updated
                    disabled={true}
                >
                    <option value={0}>Choose a portal</option>
                    {
                        portals?.map((portal: Portal, i: number) => {
                            return <option key={i} value={portal.PortalId}>{portal.Name}</option>
                        })
                    }
                </select>
            </div>
        </fieldset>
    )
}