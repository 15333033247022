import { BotConfig } from "@/constants"

type Props = {
    config: BotConfig
    isDefault: boolean
}
export default function ConfigDetails(props: Props) {

    const { config, isDefault } = props;

    return (
        <fieldset className="form-group border p-3">
            <legend>Config Details</legend>
            <div>
                <div className='text-input-label-group'>
                    <label htmlFor="configname" className='text-label'>Name:</label>
                    {config.Name}
                </div>
                <div className='text-input-label-group'>
                    <label htmlFor="configdescription" className='text-label'>Description:</label>
                    {config.Description}
                </div>
                <div className='text-input-label-group'>
                        <label htmlFor="destinationpath" className='text-label'>Destination Path:</label>
                        {config.DestPath}
                    </div>

                <div className='text-input-label-group'>
                    <label htmlFor="default-check">
                        <input
                            id="default-check"
                            type="checkbox"
                            name="isDefault"
                            checked={isDefault}
                            readOnly={true}
                        /> Default
                    </label>
                    <label htmlFor="enabled-check">
                        <input
                            id="enabled-check"
                            type="checkbox"
                            name="isEnabled"
                            checked={config.Enabled}
                            readOnly={true}
                        /> Enabled
                    </label>
                </div>
            </div>
        </fieldset>
    )
}