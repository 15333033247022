
import { useEffect, useState } from 'react';
import { useLoaderData } from "react-router-dom";

import Api, { getCred, getPortals } from "@/api/admin-api"
import CredField from '@/components/FieldSet/CredField';
import PortalFieldSet from '@/components/FieldSet/PortalSelect';
import { Portal } from '@/constants';

export async function loader({ params }: any) {
    const portals: Array<Portal> = await getPortals();
    const cred = await getCred(params.credId)
    return { cred, portals };
}

export default function CredentialForm() {
    const { cred, portals }: any = useLoaderData();

    const [selectedPortalId, setSelectedPortalId] = useState<number>(0)
    
    useEffect(() => {
        Api.getPortalByCredId(cred.CredId).then((resp: Portal) => {
            setSelectedPortalId(resp.PortalId)
        }).catch(() => {
            console.error('error getting selected portal')
        })
    }, []);

    return (
        <div>
            <h1 className='page-heading'>Cred View/Edit Page</h1>
            <div>
                <div>
                    <CredField cred={cred} />
                    <PortalFieldSet selectedPortalId={selectedPortalId} portals={portals} />
                </div>
            </div>
        </div>
    )
}
