import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";

import Api, { } from '@/api/admin-api';
import AddBtn from '@/components/Buttons/AddBtn';
import { Destination } from '@/constants';
import { hexEncode } from '@/utilities';

export async function loader({ params }: any) {
    const destinations = await Api.getDestinations();
    return { destinations };
}  

export default function Destinations() {
    const { destinations }: any = useLoaderData();

    const [destsState, setDestsState] = useState<Array<Destination>>(destinations);

    const navigate = useNavigate();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));

    function handleNavToDest(event: React.MouseEvent<HTMLElement>, destpath: string) {
        event.stopPropagation();
        navigate(`${hexEncode(destpath)}`)
    }

    return (
        <div className='route-container'>
            <div className='page-heading'>
                <h1>Destinations</h1>
                <AddBtn lable='Add a Destination' handleAdd={() => navigate('new')} />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Description</StyledTableCell>
                            <StyledTableCell align="right">Server Path</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {destsState.map((dest: Destination) => (
                            <TableRow
                                hover
                                key={dest.DestPath}
                                sx={{
                                    '*': { cursor: 'pointer' }
                                }}
                                onClick={(e) => handleNavToDest(e, dest.DestPath)}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {dest.Name}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {dest.Description}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {dest.DestPath}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}