import LoginIcon from '@mui/icons-material/Login';
import { Outlet, Link } from "react-router-dom";

export default function AnonMenu() {

  return (
    <>
      <div id="sidebar">
        <div className='nav-header'>
          <div className='home-btn-container'>
            <Link className='btn-link' to={`/`}><button type='button'>Home</button></Link>
          </div>

          <Link className='btn-link' to='login'>
            <button className='btn-internal' type='button'><LoginIcon color='info' />Login</button>
          </Link>

        </div>
      </div>
      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}