import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";

import Api, { getBots, getBotByBotCode, getPlainCredByConfigId } from '@/api/admin-api.js';
import ConfigSelect from '@/components/ConfigSelect';
// scrapers
import AndAScraper from '@/components/Scrapers/AndAScraper';
import AvpScraper from '@/components/Scrapers/AvpScraper'
import BbyCpfrScraper from '@/components/Scrapers/BbyCpfrScraper';
import DollarGeneral from "@/components/Scrapers/DgScraper";
import EdiScraper from '@/components/Scrapers/EdiScraper';
import IpcScraper from '@/components/Scrapers/IpcScraper';
import KohlsScraper from '@/components/Scrapers/KohlsScraper';
import MeijerScraper from '@/components/Scrapers/MeijerScraper';
import PolAppScraper from '@/components/Scrapers/PolAppScraper';
import PolScraper from '@/components/Scrapers/PolCustomScraper';
import VIQBackupBot from '@/components/Scrapers/VIQBackupBot';
import ViqEmailScraper from '@/components/Scrapers/ViqEmailScraper';
import WgFcScraper from '@/components/Scrapers/WgFcScraper';
import WgSunScraper from '@/components/Scrapers/WgSunScraper';
// end scrapers
import ScraperSelect from "@/components/ScraperSelect";
import { Bot, BotConfig, BotScraperIds, Credential, Job } from '@/constants';
import { getScraperCode } from '@/utilities';

import '@/style/nav.scss'

export async function loader({ params }: any) {
    const bots: Array<Bot> = await getBots();
    let configs: Array<BotConfig> | undefined = undefined;
    let bot: Bot | undefined = undefined;
    if (parseInt(params.botId) !== 0) {
        bot = await getBotByBotCode(getScraperCode(params.botId))
        configs = await Api.getBotConfigs(bot?.BotId);
    }
    let config: BotConfig | null = null;
    let cred: Credential | null = null;
    if (params.configId) {
        if (parseInt(params.configId) !== 0) {
            config = await Api.getConfig(params.configId);
            cred = await getPlainCredByConfigId(params.configId)
        }
    }
    return { bots, bot, configs, config, cred };
}

const emptyJobState = {ConfigId: 0, Name: '', Description: '', JobString: '', ModifiedBy: '', ModifiedDate: '', ModifiedReason: '', CreateDate: ''}

export default function RunBots() {
    const { bots, bot, configs, config, cred }: any = useLoaderData();
    const navigate = useNavigate();

    const [configState, setConfigState] = useState<BotConfig>(config);
    const [credState, setCredState] = useState<Credential>(cred);
    const [jobState, setJobState] = useState<Job | null>(emptyJobState);

    useEffect(() => {
        let ignore = false;
        setConfigState(config)
        setCredState(cred)
        if(config) {
            Api.getJob(config.ConfigId).then((resp: Job) => {
                if (!ignore) {
                    setJobState(resp)
                }
            }).catch(() => {
              setJobState(emptyJobState)
            })
        } else{
            setJobState(emptyJobState)
        }
        return () => {
            ignore = true;
        };
    },[config, cred]);

    const Scraper = getScrapers().find((scraper: any) => scraper.id === getScraperCode(bot?.RunApiEndpoint))?.scraper

    // basically map scraper ids to the scraper component
    function getScrapers() {
        return [
            {id: BotScraperIds.MEIJER, scraper: MeijerScraper},
            {id: BotScraperIds.IPC, scraper: IpcScraper},
            {id: BotScraperIds.DG, scraper: DollarGeneral},
            {id: BotScraperIds.KOHLS, scraper: KohlsScraper},
            {id: BotScraperIds.VIQ, scraper: VIQBackupBot},
            {id: BotScraperIds.WGFORECAST, scraper: WgFcScraper},
            {id: BotScraperIds.WGSUNDAY, scraper: WgSunScraper},
            {id: BotScraperIds.VIQEMAIL, scraper: ViqEmailScraper},
            {id: BotScraperIds.CPFR, scraper: BbyCpfrScraper},
            {id: BotScraperIds.AVP, scraper: AvpScraper},
            {id: BotScraperIds.ANDA, scraper: AndAScraper},
            {id: BotScraperIds.POL_CUSTOM, scraper: PolScraper},
            {id: BotScraperIds.POL_APP, scraper: PolAppScraper},
            {id: BotScraperIds.EDI, scraper: EdiScraper}
        ]
    }

    return (
        <>
            <h1 className='page-heading'>Manually Run/Debug/Monitor Bots</h1>
            <div id='bot-select-nav'>
                <ScraperSelect
                    selectScraper = {
                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                            navigate(`/bots/${e.target.value}/configs/0/debug`);
                        }
                    }
                    botId={bot?.BotId}
                    bots={bots}
                    botCode={getScraperCode(bot?.RunApiEndpoint) ? getScraperCode(bot?.RunApiEndpoint) : 0 }
                />
                <ConfigSelect
                    selectConfig = {
                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                            navigate(`/bots/${getScraperCode(bot?.RunApiEndpoint)}/configs/${e.target.value}/debug`);
                        }
                    }
                    configs={configs}
                    configId={config?.ConfigId ? config?.ConfigId : 0}
                    bot={bot}
                />
            </div>
            <div className='bot'>
                {
                    Scraper ?
                        <Scraper
                            botCode={getScraperCode(bot?.RunApiEndpoint)}
                            name={bot?.Name}
                            description={bot?.Description}
                            config={configState}
                            setConfigState={setConfigState}
                            cred={credState}
                            setCredState={setCredState}
                            job={jobState}
                            setJobState={setJobState}
                        />
                    : 
                    <div className='nobot'>
                        <p>Select a scraper!!!</p>
                    </div>
                }
            </div>
        </>
    )
}
