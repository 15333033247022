import PasswordTwoToneIcon from '@mui/icons-material/PasswordTwoTone';
import { AlertColor } from '@mui/material/Alert';
import { Link, useLoaderData } from "react-router-dom";

import { getCamps, deleteCampaign } from '@/api/campaign-api';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Campaign, SnackbarSeverity } from '@/constants';

export async function loader({ params }: any) {
    const camps = await getCamps();
    return { camps };
}  

export default function Campaigns() {
    const { camps }: any = useLoaderData();

    // useEffect(() => {
    // }, [])

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };  

    function handleDeleteCampaign(e: any) {
        if (window.confirm(`Are you sure you want to delete campaign: "${e.Name}"?`)) {
            deleteCampaign(e.CampaignId).then((resp: Campaign) => {
                handleShowSnackBar(`Deleted campaign: ${resp.Name}`, SnackbarSeverity.SUCCESS);
            }).catch((err: any) => {
                handleShowSnackBar(`Error deleting campaign: ${err.statusText}`, SnackbarSeverity.ERROR);
            })
        }
    }

    return (
        <div>
            <h1 className='page-heading'>Campaigns</h1>
            <ul>
                {camps.length > 0 ? 
                camps.map((camp: Campaign, i: number) =>
                    <li id={`camp-${i}`} key={camp.CampaignId}>
                        <div>{camp.Name}</div>
                        <Link to={`/campaigns/${camp.CampaignId}`}><button className='btn btn-info' id={`edit-camp-${i}`} type="button">View/Edit</button></Link>
                        <Link to={`/entry/campaigns/${camp.CampaignId}/credentials`}><button type="button" title='cred-entry' className='clickable btn btn-secondary'><PasswordTwoToneIcon /></button></Link>
                        <button disabled={false} id={'delete-campaign' + i} type="button" className='btn btn-danger' onClick={() => handleDeleteCampaign(camp)}>Delete</button>
                    </li>
                )
                : <li>None</li>
                }
            </ul>
        </div>
    )
}