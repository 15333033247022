import { faPenToSquare, faCopy, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertColor } from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity, Job } from '@/constants';
import { copyToClipboard } from '@/utilities';

type ScrapeJobProps = {
    job: Job | null
    setJob: any
    placeHolder: string
    disabled: boolean
};

export default function ScrapeJob(props: ScrapeJobProps) {
    const {
        job,
        setJob,
        placeHolder,
        disabled
    } = props

    useEffect(() => {
        if (setJob)
            setJob(job)
    }, [job])

    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const [isJobGood, setIsJobGood] = useState<boolean>(true);
    const [invalidJsonMessage, setInvalidJsonMessage] = useState<string>('');

    function handleChangeJobStr(e: React.ChangeEvent<HTMLTextAreaElement>) {
        e.preventDefault();
        let jobstring = '';
        try {
            jobstring = e.target.value.replace(/'/g, '"')
            JSON.parse(jobstring);
            setIsJobGood(true)
            setInvalidJsonMessage('')
            handleShowSnackBar('Job string good.', SnackbarSeverity.SUCCESS);
        } catch (err: any) {
            setIsJobGood(false)
            setInvalidJsonMessage(err.message)
        } finally {
            setJob({...job, JobString: jobstring})
        }
    }

    function handleCopyJobTemplate() {
        copyToClipboard(placeHolder)
        handleShowSnackBar('Copied job template to clipboard.', SnackbarSeverity.SUCCESS);
    }

    async function handleNavigateToJob(configId: number | undefined) {
        if (configId) {
            navigate(`/configs/${configId}/job`)
        } else {
            handleShowSnackBar('First select a config.', SnackbarSeverity.WARNING);
        }
    }

    return (
        <div id='job-input'>
            <label htmlFor='jobstring'>Job String</label>
            <textarea 
                rows={2} cols={20} name="jobstring" title="jobstring" id="jobstring"
                wrap="hard"
                className={isJobGood ? 'my-text-input form-control' : 'my-text-input form-control invalid-form'}
                value={job?.JobString ?? ''}
                required={true}
                placeholder={placeHolder}
                disabled={disabled}
                onChange={handleChangeJobStr}
            />
            {placeHolder != 'n/a' && <Tooltip title='copy job template'><div className='btn custom' onClick={handleCopyJobTemplate}><FontAwesomeIcon icon={faCopy} /></div></Tooltip>}
            {job?.ConfigId != 0 && location.pathname.includes('debug') && <Tooltip title='edit this config job'><div className='btn custom' onClick={() => handleNavigateToJob(job?.ConfigId)}><FontAwesomeIcon icon={faPenToSquare} /></div></Tooltip>}
            {!isJobGood && <div id='job-error'>
                <FontAwesomeIcon icon={faCircleExclamation} color='red'/>
                <span>&quot;{invalidJsonMessage}&quot;</span>
            </div>}
        </div>
    )
}
