import DestSelect from "./DestinationsSelect"

import { BotConfig, Destination } from "@/constants"

type Props = {
    config: BotConfig
    // setConfig: any
    isDefault: boolean
    // setIsDefault: any
    // destinations: Array<Destination>
    // setDest: any
}

export default function EditConfigDetails(props: Props) {
    const {
        config,
        isDefault,
        // destinations,
        // setDest,
    } = props

    return (
        <fieldset className="form-group border p-3">
            <legend>Config Details</legend>
            <div>
                <div className='text-input-label-group'>
                    <label htmlFor="configname" className='text-label'>Name</label>
                    <input
                        type="text"
                        id="configname"
                        name='name'
                        autoFocus
                        required={true}
                        defaultValue={config.Name}
                    />
                </div>
                <div className='text-input-label-group'>
                    <label htmlFor="configdescription" className='text-label'>Description</label>
                    <input
                        type="textarea"
                        id="configdescription"
                        name='description'
                        required={true}
                        defaultValue={config.Description}
                    />
                </div>
                <div className='text-input-label-group'>
                    <label htmlFor="default-check">
                        <input
                            id="default-check" 
                            type="checkbox"
                            name="isDefault"
                            defaultChecked={isDefault}
                        /> Default
                    </label>
                    <label htmlFor="enabled-check">
                        <input
                            id="enabled-check" 
                            type="checkbox"
                            name="isEnabled"
                            defaultChecked={config.Enabled}
                        /> Enabled
                    </label>
                </div>
            </div>
        </fieldset>
    )
}
