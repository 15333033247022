import $ from 'jquery';
import apiServer from "./serverInfo";

const scraperapi = {
    // generic run a bot
    genericRunBot: (apiEndpoint, configId) => {
        return $.ajax({
            url: `${apiServer}${apiEndpoint}/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            }
        });
    },

    // DgScrape.js - Dollar General
    scrapeDg: (configId, dgLogin) => {
        return $.ajax({
            url: `${apiServer}api/scrape/dg/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dgLogin
        });
    },

    // MeijerScrape.js - Meijer (Microstrategy)
    scrapeMeijer: (configId, meijerDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/meijer/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: meijerDto
        });
    },

    // kohls api
    scrapeKohls: (configId, kohlsDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/kohls/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: kohlsDto
        });
    },

    // pol vdsid
    getPolVds: (configId, clearCache) => {
        return $.ajax({
            url: `${apiServer}api/scrape/pol/vds/${configId}?clearCache=${clearCache}`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    // pol app card
    debugScrapePolApp: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/polapp/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },
    scrapePolApp: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/polapp/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },
    
    // pol custom card
    debugScrapePolCustom: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/polcustom/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },
    scrapePolCustom: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/polcustom/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },

    // ipc api
    scrapeIpc: (configId, ipcDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/ipc/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: ipcDto
        });
    },

    // bbycpfr
    scrapeBbyCpfr: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/bbycpfr/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },

    // avp
    scrapeAvp: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/avp/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },

    // a&a
    scrapeAndA: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/anda/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },

    // edi
    scrapeEdi: (configId, dto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/edi/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: dto
        });
    },
    
    // viq api
    getViqBackCandids: () => {
        return $.ajax({
            url: `${apiServer}api/scrape/viq/candidates`,
            type: 'GET',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    scrapeViq: (configId, viqLogin) => {
        return $.ajax({
            url: `${apiServer}api/scrape/viq/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: viqLogin
        });
    },
    configViqEmail: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/emailss/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
        });
    },

    // walgreens api
    wgCustomLogin: (configId, loginDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/wglogin/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: loginDto
        });
    },
    wgLogin: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/wglogin/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    // sunday
    wgCustomSun: (configId, sunDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/wgsun/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: sunDto
        });
    },
    wgSun: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/wgsun/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
        });
    },
    // forecast
    wgCustomForecast: (configId, forecastDto) => {
        return $.ajax({
            url: `${apiServer}api/scrape/wgforecast/${configId}/debug`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: forecastDto
        });
    },
    wgForecast: (configId) => {
        return $.ajax({
            url: `${apiServer}api/scrape/wgforecast/${configId}`,
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
        });
    }
}

export default scraperapi;