import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { useLoaderData } from "react-router-dom";

import { getPortals, postBot } from "@/api/admin-api"
import SaveNewBtn from '@/components/Buttons/SaveNewBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Portal, SnackbarSeverity } from '@/constants';


export async function loader() {
    const portals = await getPortals();
    return { portals };
}

export default function NewBot() {
    const { portals }: any = useLoaderData();

    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const [name, setDestName] = useState<string>('');
    const [desc, setDestDesc] = useState<string>('');
    const [endPoint, setEndPoint] = useState<string>('');
    const [selectedPortal, setSelectedPortal] = useState<string>('');

    function handleSubmit(e: any) {
        e.preventDefault();
        const formJson = Object.fromEntries(new FormData(e.target).entries());

        postBot(formJson)
            .then((res: any) => {
                handleShowSnackBar(`Successfully created the bot.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Failed to create the bot.`, SnackbarSeverity.ERROR);
                console.error(error.responseText)
        });
    }

    function handleSelectPortal(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
        setSelectedPortal(e.target.value)
    }

    return (
        <div>
            <h1 className='page-heading'>Add a Bot Page</h1>
            <div>
                <div>
                    <form onSubmit={handleSubmit}>
                        <fieldset className="form-group border p-3">
                            <legend>Bot Info</legend>
                            <div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="name" className='text-label'>Name:</label>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        id="name"
                                        required={true}
                                        placeholder='name'
                                        value={name}
                                        onChange={e => setDestName(e.target.value)}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="description" className='text-label'>Description:</label>
                                    <input 
                                        type="text" 
                                        name="description" 
                                        id="description"
                                        required={true}
                                        placeholder='description'
                                        value={desc}
                                        onChange={e => setDestDesc(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='text-input-label-group'>
                            <label htmlFor="RunApiEndpoint" className='text-label'>API Endpoint:</label>
                                <input 
                                    type="text" 
                                    name="RunApiEndpoint" 
                                    id="RunApiEndpoint"
                                    required={true}
                                    value={endPoint}
                                    onChange={e => setEndPoint(e.target.value)}
                                    placeholder='api/scrape/{endpoint}'
                                />
                        </div>

                        <div className='text-input-label-group'>
                            <label htmlFor="portals" className='text-label'>Portal:</label>
                            <div>
                                <select id='portals' className="form-select" aria-label="portal select" name="portalid" title='portals' onChange={handleSelectPortal} required={true} value={selectedPortal}>
                                    <option value={0}>Choose a portal</option>
                                    {
                                        portals.map((portal: Portal, i: number) => {
                                            return <option key={i} value={portal.PortalId}>{portal.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        </fieldset>
                        <SaveNewBtn text='Add Bot' />
                    </form>
                </div>
            </div>
        </div>
    )
}
