import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { useLoaderData } from "react-router-dom";

import { getBotConfigs, getPortals, putBot, deleteBot, getBotByBotCode } from '@/api/admin-api';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Bot, SnackbarSeverity, Portal } from '@/constants';
import Configs from '@/routes/Configs/Configs';

export async function loader({ params }: any) {
    const bot = await getBotByBotCode(params.botId);
    let configs = undefined;
    if(bot)
        configs = await getBotConfigs(bot.BotId)
    const portals = await getPortals()
    return { bot, configs, portals };
}

export default function BotForm() {
    const { bot, configs, portals }: any = useLoaderData();

    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const [updateBot, setPutBot] = useState<boolean>(false);
    const [name, setName] = useState<string>(bot.Name);
    const [desc, setDesc] = useState<string>(bot.Description);
    const [endPoint, setEndPoint] = useState<string>(bot.RunApiEndpoint);

    const [selectedPortal, setSelectedPortal] = useState<string>(bot.PortalId);

    // useEffect(() => {
    // }, [])

    function handleBotUpdate(e: any) {
        e.preventDefault();

        const formJson = Object.fromEntries(new FormData(e.target).entries());
        formJson.BotId = bot.BotId;

        putBot(bot.BotId, formJson)
            .then((res: any) => {
                handleShowSnackBar(`Successfully updated the bot.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Failed to update the bot.`, SnackbarSeverity.ERROR);
        });
    }

    function handleDeleteBot() {
        if (window.confirm(`Are you sure you'd like to delete the bot?`)) {
            deleteBot(bot.BotId)
                .then((res: any) => {
                    handleShowSnackBar(`Successfully deleted the bot.`, SnackbarSeverity.SUCCESS);
                }).catch((error: any) => {
                    handleShowSnackBar(`Failed to delete the bot: ${error.statusText}`, SnackbarSeverity.ERROR);
            });
        }
    }

    async function handleSelectPortal(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
        setSelectedPortal(e.target.value)
    }

    return (
        <div>
            <h1 className='page-heading'>{bot.Name} Bot</h1>
            <form onSubmit={handleBotUpdate}>
                <fieldset className="form-group border p-3">
                    <legend>Bot Info</legend>
                    <div>
                        <div className='text-input-label-group'>
                            <label htmlFor="name" className='text-label'>Name:</label>
                            {updateBot ?
                                <input 
                                    type="text" 
                                    name="name" 
                                    id="name"
                                    required={true}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            :   <div>
                                    {name}
                                </div>
                            }
                        </div>
                        <div className='text-input-label-group'>
                            <label htmlFor="description" className='text-label'>Description:</label>
                            {updateBot ?
                                <input 
                                    type="text" 
                                    name="description" 
                                    id="description"
                                    required={true}
                                    value={desc}
                                    onChange={e => setDesc(e.target.value)}
                                />
                            :   <div>
                                    {desc}
                                </div>
                            }
                        </div>
                        <div className='text-input-label-group'>
                            <label htmlFor="RunApiEndpoint" className='text-label'>API Endpoint:</label>
                            {updateBot ?
                                <input 
                                    type="text" 
                                    name="RunApiEndpoint" 
                                    id="RunApiEndpoint"
                                    required={true}
                                    value={endPoint}
                                    onChange={e => setEndPoint(e.target.value)}
                                />
                            :   <div>
                                    {endPoint}
                                </div>
                            }
                        </div>

                        <div className='text-input-label-group'>
                            <label htmlFor="portals" className='text-label'>Portal:</label>
                            <div>
                                {updateBot ? <select name="portalid" title='portals' id='portals' onChange={handleSelectPortal} required={true} value={selectedPortal}>
                                    <option value={0}>Choose a portal</option>
                                    {
                                        portals.map((portal: Portal, i: number) => {
                                            return <option key={i} value={portal.PortalId}>{portal.Name}</option>
                                        })
                                    }
                                </select>
                                : <div> {(() =>
                                            portals.find((portal: Portal) => bot.PortalId === portal.PortalId).Name
                                        )()}
                                </div>}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <button type="button" className='btn btn-secondary' onClick={() => setPutBot(!updateBot)}>{updateBot ? '<- View' : 'Edit'}</button>
                {!updateBot ? <button disabled={configs?.length > 0} type="button" className='btn btn-danger' onClick={e => {
                        e.preventDefault()
                        handleDeleteBot()
                }}>Delete</button>: <></>}
                {updateBot ? <button type="submit" className='btn btn-primary'>Submit</button> : <></>}
            </form>
            <div>
                <Configs />
            </div>
        </div>
    )
}