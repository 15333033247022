import { faCalendarXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DangerousTwoToneIcon from "@mui/icons-material/DangerousTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from '@mui/icons-material/FilterList';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RefreshIcon from "@mui/icons-material/Refresh";
import SortIcon from '@mui/icons-material/Sort';
import { IconButton } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import parser from "cron-parser";
import dayjs, { Dayjs } from "dayjs";
import { useState, useEffect } from "react";
import React from "react";
import { Link, useLoaderData, useNavigate, Await, defer } from "react-router-dom";

import Api, {
    getConfigs,
    getBotByConfigId,
    getBots,
} from "@/api/admin-api";
import Scrape from '@/api/scraper-api'
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { BotConfig, ConfigRunMetric, Bot, SnackbarSeverity } from "@/constants";
import { getScraperCode } from "@/utilities";

import '@/components/Buttons/buttons.scss'
import '@/style/overview.scss'

export async function loader() {
    async function myBla() {
        // Rather than awaiting in-order, using Promise.all will
        // execute all requests at the same time. Makes things
        // slightly faster.
        return await Promise.all([
            getConfigs(),
            Api.getMetrics(),
            Api.getAreScraping(),
            getBots(),
        ]).then((results) => {
            return {
                configs: results[0],
                metrics: results[1],
                areScraping: results[2],
                bots: results[3],
            };
        });
    }
    return defer({ pageData: myBla() });
}

/**
 * Returns a fallback loading component until defered async data is fulfilled, at
 * which point the full template is rendered.
 */
export default function BotsOverview() {
    const pageData: any = useLoaderData();

    return (
        <React.Suspense fallback={<p>Loading overview...</p>}>
            <Await
                resolve={pageData.pageData}
                errorElement={<p>Error loading overview!</p>}
            >
                {(actualData) => <BotsOverviewTemplate data={actualData} />}
            </Await>
        </React.Suspense>
    );
}

/**
 * Bots overview template
 */
function BotsOverviewTemplate(props: {
    data: {
        configs: any;
        metrics: any;
        areScraping: any;
        bots: any;
    };
}): React.ReactElement {

    const pageData = props.data;

    const navigate = useNavigate();
    const showSnack = useSnackbar();

    const [filteredConfigs, setfilteredConfigs] = useState<Array<BotConfig>>(pageData.configs.filter((config: BotConfig)=>config.Enabled));
    const [sortOrder, setSortOrder] = useState<boolean>(true);

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    useEffect(()=> {
        handleSortByNextStart()
    }, [])

    
    function parseSchedule(cronTab: string, lastStart: Date | null): { isOnSchedule: boolean; nextStart: Dayjs | undefined } {
        try {
            if (lastStart && cronTab != "None") {
                const cron = parser.parseExpression(cronTab);
                const cronPrev = dayjs(new Date(cron.prev().toString()).toLocaleString());
                const cronNext: Dayjs = dayjs(new Date(cron.next().toString()).toLocaleString());
                // cron.reset()
                // cron interval (expected)
                // const cronInterval = cronNext.diff(cronPrev, 'm');

                const now = dayjs();
                const actualRun = dayjs(lastStart)

                // cron time fields
                const cronSec = parseInt(cron.fields.second.toString())
                const cronMin = parseInt(cron.fields.minute.toString())
                const cronHour = parseInt(cron.fields.hour.toString())

                // create date from crontab to get expected run
                const expectedRun = dayjs(new Date(cronPrev.year(), cronPrev.month(), cronPrev.date(), cronHour, cronMin, cronSec))

                // get standard deviation
                const standardDeviation = actualRun.diff(expectedRun, 'm');
                // on time if standard deviation is >= 0
                const ontime: boolean = standardDeviation >= 0;
                // check actual run within standard deviation
                const actualErrorMargin = actualRun.diff(now, 'm');
                const withinStandardDeviation: boolean = actualErrorMargin - standardDeviation >= 0;
                // on schedule if within standard deviation or on time
                const onschedule = withinStandardDeviation || ontime;

                return {
                    isOnSchedule: onschedule,
                    nextStart: cronNext as Dayjs
                };
            } else if (cronTab != "None") {
                const interval = parser.parseExpression(cronTab);
                return {
                    isOnSchedule: false,
                    nextStart: dayjs(new Date(interval.next().toString()).toLocaleString()),
                };
            }
            return {
                isOnSchedule: false,
                nextStart: undefined
            }
        } catch (err: any) {
            console.log("Error: " + err.message);
            return {
                isOnSchedule: false,
                nextStart: undefined
            }
        }
    }

    async function handleConfigRun(configId: number) {
        const bot: Bot = await getBotByConfigId(configId);
        Scrape.genericRunBot(bot.RunApiEndpoint, configId)
            .then((resp: ConfigRunMetric) => {
                // console.log(`success: ${JSON.stringify(resp)}`);
                handleShowSnackBar(`Started the scraper.`, SnackbarSeverity.SUCCESS)
                // TODO: refactor configs state (filtertedconfigs) to include "isScraping"
                // const config = pageData.configs.find(
                //     (config: Config) =>
                //         config.ConfigId === configId
                // );
            })
            .catch((err: any) => {
                console.error(`error: ${err.statusText}`);
                handleShowSnackBar(`Error starting the bot: ${err.statusText}.`, SnackbarSeverity.ERROR)
            });
    }

    function handleFilterConfigs(e: any) {
        if (e.target.value === "- none -") {
            setfilteredConfigs(pageData.configs);
            return;
        }
        const bot: Bot = pageData.bots.find(
            (bot: Bot) => getScraperCode(bot.RunApiEndpoint) === e.target.value
        );
        const filteredConfigs: Array<BotConfig> = pageData.configs.filter(
            (config: BotConfig) => config.BotId === bot.BotId
        );
        setfilteredConfigs(filteredConfigs);
    }

    function handleSortByNextStart() {
        setSortOrder(!sortOrder)
        const nextList = [...filteredConfigs]
        nextList.sort((a: BotConfig, b: BotConfig) => {
            let cronA;
            let cronB;
            if (a.CronTab !== 'None') {
                // new Date(cron.prev().toString()).toLocaleString();
                cronA = new Date(parser.parseExpression(a.CronTab).next().toString()).getTime();
            } else {
                cronA = new Date().getTime();
            }
            if (b.CronTab !== 'None') {
                cronB = new Date(parser.parseExpression(b.CronTab).next().toString()).getTime();
            } else {
                cronB = new Date().getTime();
            }
            return (sortOrder ? cronA - cronB : cronB - cronA);
        });
        setfilteredConfigs(nextList);
    }

    function handleRefresh() {
        navigate(0);
    }

    async function handleNavigateToConfig(event: React.MouseEvent<HTMLElement>, configId: number) {
        event.stopPropagation();
        const botcode: Bot = await getBotByConfigId(configId);
        const scraperCode = getScraperCode(botcode.RunApiEndpoint);
        navigate(`/bots/${scraperCode}/configs/${configId}/debug`)
    }

    return (
        <div className='route-container'>
            <div className="header-container">
                <h1 className="page-heading">Scrapers Overview</h1>
                <div>
                    <Tooltip title='refresh overview'>
                        <IconButton onClick={handleRefresh}>
                        <RefreshIcon />
                    </IconButton></Tooltip>
                </div>
            </div>

            <div>
                <table className="output-panel">
                    <thead>
                        <tr>
                            <th className='left'>
                                <label className="custom-label" htmlFor="bot-filter">
                                    Bot <FilterListIcon />
                                </label>
                                <select
                                    onChange={handleFilterConfigs}
                                    id="bot-filter"
                                    className="custom-select"
                                >
                                    <option>- none -</option>
                                    {pageData.bots?.map((bot: Bot) => (
                                        <option key={getScraperCode(bot.RunApiEndpoint)}>
                                            {getScraperCode(bot.RunApiEndpoint)}
                                        </option>
                                    ))}
                                </select>
                            </th>
                            <th className='left'>Config Name</th>
                            <th className='left' onClick={handleSortByNextStart}>
                                Next Start
                                <button className="btn custom" type='button'>
                                    <SortIcon />
                                    {sortOrder ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                </button>
                            </th>
                            <th className='left'>Last Start</th>
                            <th className='left'>Last Message</th>
                            <th className='center'>Status</th>
                            <th className='right'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredConfigs?.length > 0 ? (
                            filteredConfigs?.map(
                                (config: BotConfig, i: number) => {
                                    const myMetric: ConfigRunMetric = pageData.metrics.find((metric: ConfigRunMetric) => metric.ConfigId === config.ConfigId);
                                    const isScraping: number = pageData.areScraping.find((configId: number) => config.ConfigId === configId);
                                    const bot: Bot = pageData.bots.find((bot: Bot) => config.BotId === bot.BotId);
                                    const botCode = getScraperCode(bot.RunApiEndpoint);
                                    const schedule = parseSchedule(config.CronTab, myMetric?.LastRunStartTime);
                                    const nextStart = schedule.nextStart?.format('ddd MMM D YYYY - hh:mm:ss A');
                                    let lastStart = ''
                                    if (myMetric?.LastRunStartTime) {
                                        const dateStamp = Date.parse(myMetric.LastRunStartTime.toString())
                                        const date = new Date(dateStamp).toDateString()
                                        const time = new Date(dateStamp).toLocaleTimeString()
                                        lastStart = `${date} - ${time}`
                                    }
                                    return (
                                        <tr
                                            id={`config-${i}`}
                                            key={i}
                                            className="output-row overview-row"
                                            onClick={(e) => handleNavigateToConfig(e, config.ConfigId)}
                                        >
                                            <td className="output-header overview-header">
                                                <strong>[{botCode}]</strong>{" "}
                                            </td>
                                            <td className="output-text">
                                                {config.Name}
                                            </td>
                                            <td className="output-text">
                                                {nextStart}
                                            </td>
                                            <td className={schedule?.isOnSchedule ? 'output-text' : 'output-text warn'}>
                                                {lastStart}
                                            </td>
                                            <td className="output-text">
                                                {myMetric?.CurrentMessage}
                                            </td>
                                            <td>
                                                <div className="overview-btn">
                                                    {isScraping ? <CircularProgress />
                                                    : schedule?.isOnSchedule && myMetric?.WasSuccess ? <CheckCircleTwoToneIcon color="success" />
                                                    : !schedule?.isOnSchedule ? <Tooltip title='Off schedule.'><FontAwesomeIcon icon={faCalendarXmark} className="danger" size="lg" /></Tooltip>
                                                    : <DangerousTwoToneIcon color="error" />
                                                    }
                                                </div>
                                            </td>
                                            <td className='actions'>
                                                <Link
                                                    to={`/configs/${config.ConfigId}`}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <button type="button" title="edit-config">
                                                        <EditIcon />
                                                    </button>
                                                </Link>
                                                <button
                                                    type="button" title="run-config"
                                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                        e.stopPropagation()
                                                        handleConfigRun(config.ConfigId);
                                                    }}
                                                    disabled={ isScraping ? true : false }
                                                >
                                                    <PlayArrowIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                }
                            )
                        ) : (
                            <tr>
                                <td>None</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
