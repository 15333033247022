import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";

import { getPortals, putPortal, postPortal, deletePortal } from "@/api/admin-api";
import AddBtn from '@/components/Buttons/AddBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity, Portal } from '@/constants';

export async function loader() {
    const portals: Array<Portal> = await getPortals();
    return { portals };
}

type ToggleEdit = {
    toggle: boolean,
    key: number,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

export default function Portals() {
    const { portals }: any = useLoaderData();
    const showSnack = useSnackbar();

    const [portalsState, setPortalsState] = useState<Array<Portal>>(portals);

    const [toggleEdit, setToggleEdit] = useState<ToggleEdit>({toggle: false, key: 0});

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const navigate = useNavigate();

    function handleEditRow(event: React.MouseEvent<HTMLElement>, portal: Portal, key: any) {
        event.stopPropagation();
        setToggleEdit({toggle: !toggleEdit.toggle, key: key})
    }

    function handleSave(portalId: number, i: number) {
        const portalToUpdate = portalsState.find((portal: Portal) => portal.PortalId === portalId)
        putPortal(portalId, portalToUpdate).then(() => {
            setToggleEdit({toggle: false, key: i})
            handleShowSnackBar(`Updated the portal.`, SnackbarSeverity.SUCCESS)
        }).catch(() => {
            handleShowSnackBar(`Failed to update the portal.`, SnackbarSeverity.ERROR)
        })
    }

    function handlePostPortal(portalId: number, i: number) {
        const portalToUpdate = portalsState.find((portal: Portal) => portal.PortalId === portalId)
        postPortal(portalToUpdate).then((resp: Portal) => {
            const updatedPortal: Portal = 
            {
                PortalId: resp.PortalId,
                Name: resp.Name,
                Description: resp.Description,
                Domain: resp.Domain
            }
            const newPortals = portalsState.slice()
            newPortals[i] = updatedPortal
            setPortalsState(newPortals)
            setToggleEdit({toggle: false, key: i})
            handleShowSnackBar(`Added the portal.`, SnackbarSeverity.SUCCESS)
        }).catch(() => {
            handleShowSnackBar(`Failed to add the portal.`, SnackbarSeverity.ERROR)
        })
    }

    function handleAddPortal() {
        const portalToAdd: Portal = 
        {
            PortalId: 0,
            Name: '',
            Description: '',
            Domain: ''
        }
        setPortalsState([...portalsState, portalToAdd])
        setToggleEdit({toggle: true, key: portalsState.length})
        window.scrollTo(0, document.body.scrollHeight);
    }

    function handleDeletePortal(event: React.MouseEvent<HTMLElement>, portalId: number) {
        event.stopPropagation();
        deletePortal(portalId).then(() => {
            const deletedPortal = portalsState.find((portal: Portal) => portal.PortalId === portalId);
            setPortalsState(portalsState.filter((portal: Portal) => portal.PortalId !== portalId));
            handleShowSnackBar(`Deleted the portal:  "${deletedPortal?.Name}".`, SnackbarSeverity.SUCCESS)
        }).catch((err: any) => {
            let message = ''
            try {
                message = JSON.parse(err.responseText).Message
            } catch {
                message = 'unexpected error'
            } finally {
                handleShowSnackBar(`Delete fail: ${message}.`, SnackbarSeverity.ERROR)
            }
        })
    }

    function handleNavToPortal(event: React.MouseEvent<HTMLElement>, portalId: string) {
        event.stopPropagation();
        navigate(portalId)
    }

    return (
        <div className='route-container'>
            <div className='page-heading'>
                <h1>Portals</h1>
                {!toggleEdit.toggle && <AddBtn handleAdd={handleAddPortal} lable='Add a Portal' />}
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Description</StyledTableCell>
                            <StyledTableCell align="right">Domain Address</StyledTableCell>
                            <StyledTableCell align="right" colSpan={3}>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {portalsState.map((portal: Portal, i: number) => (
                            <TableRow
                                key={portal.PortalId}
                                sx={{
                                    '*': { cursor: 'pointer' }
                                }}
                                onClick={(e: React.MouseEvent<HTMLElement>) => handleNavToPortal(e, `${portal.PortalId}`)}
                                hover
                            >
                                {toggleEdit.toggle && toggleEdit.key == i ?
                                    <>
                                        <StyledTableCell component="th" scope="row">
                                            <TextField
                                                onClick={(e) => e.stopPropagation()}
                                                required
                                                id={`${i}-outlined-required`}
                                                label="Name"
                                                defaultValue={portal.Name}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setPortalsState((prevState) => {
                                                    const newState = [...prevState];
                                                    newState[i].Name = e.target.value;
                                                    return newState;
                                                })}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <TextField
                                                onClick={(e) => e.stopPropagation()}
                                                required
                                                id={`${i}-outlined-required`}
                                                label="Description"
                                                defaultValue={portal.Description}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setPortalsState((prevState) => {
                                                    const newState = [...prevState];
                                                    newState[i].Description = e.target.value;
                                                    return newState;
                                                })}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <TextField
                                                onClick={(e) => e.stopPropagation()}
                                                required
                                                id={`${i}-outlined-required`}
                                                label="Domain Address"
                                                defaultValue={portal.Domain}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setPortalsState((prevState) => {
                                                    const newState = [...prevState];
                                                    newState[i].Domain = e.target.value;
                                                    return newState;
                                                })}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="right" colSpan={3}>
                                            {portal.PortalId === 0 ? <Button 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handlePostPortal(portal.PortalId, i);
                                                }}><SaveTwoToneIcon color='success' /></Button>
                                            : <Button onClick={(e) => {
                                                e.stopPropagation();
                                                handleSave(portal.PortalId, i);
                                            }}><SaveTwoToneIcon color='success' /></Button>}
                                            <Button onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                                    event.stopPropagation();
                                                    const portals = await getPortals()
                                                    setToggleEdit({toggle: false, key: i})
                                                    setPortalsState(portals);
                                                }} color='inherit'><CloseIcon />
                                            </Button>
                                        </StyledTableCell>
                                    </>
                                :
                                    <>
                                        <TableCell component="th" scope="row">
                                            {portal.Name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {portal.Description}
                                        </TableCell>
                                        <TableCell align="right">
                                            {portal.Domain}
                                        </TableCell>
                                        <TableCell align="right" colSpan={3}>
                                            <div className='action-set'>
                                                <div className='btn btn-secondary' onClick={(e: React.MouseEvent<HTMLElement>) => handleEditRow(e, portal, i)} color='inherit'><EditTwoToneIcon /></div>
                                                <div className='btn btn-danger' onClick={(e: React.MouseEvent<HTMLElement>) => handleDeletePortal(e, portal.PortalId)}><DeleteForeverTwoToneIcon /></div>
                                            </div>
                                        </TableCell>
                                    </>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}