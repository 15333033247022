import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import PestControlIcon from "@mui/icons-material/PestControl";
import { AlertColor } from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";

import { getBots, deleteBot } from '@/api/admin-api';
import AddBtn from '@/components/Buttons/AddBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Bot, SnackbarSeverity } from '@/constants';
import { getScraperCode } from "@/utilities";

export async function loader() {
    const bots = await getBots();
    return { bots };
}  

export default function Bots() {
    const { bots }: any = useLoaderData();
    const showSnack = useSnackbar();

    const [botsState, setBotsState] = useState<Array<Bot>>(bots);

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const navigate = useNavigate();

    function handleDeleteBot(event: React.MouseEvent<HTMLElement>, botId: number) {
        event.stopPropagation();
        if (window.confirm(`Are you sure you'd like to delete the bot?`)) {
            deleteBot(botId).then(() => {
                setBotsState(botsState.filter(a => a.BotId !== botId));
                handleShowSnackBar(`Successfully deleted the bot.`, SnackbarSeverity.SUCCESS);
            }).catch((err: any) => {
                let message = ''
                try {
                    message = JSON.parse(err.responseText).Message
                } catch {
                    message = 'unexpected error'
                } finally {
                    handleShowSnackBar(`Delete fail: ${message}.`, SnackbarSeverity.ERROR);
                }
            })
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));

    function handleNavToBot(event: React.MouseEvent<HTMLElement>, scraperCode: string, debug: boolean) {
        event.stopPropagation();
        if (debug)
            navigate(`${scraperCode}/configs/0/debug`)
        else
            navigate(`${scraperCode}`)
    }

    return (
        <div className='route-container'>
            <div className='page-heading'>
                <h1>Bots</h1>
                <AddBtn lable='Add a Bot' handleAdd={() => navigate('new')} />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className='mui-head'>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Description</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {botsState.map((bot: Bot) => (
                            <TableRow
                                hover
                                key={bot.BotId}
                                sx={{
                                    '*': { cursor: 'pointer' }
                                }}
                                onClick={(e) => handleNavToBot(e, `${getScraperCode(bot.RunApiEndpoint)}`, false)}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {bot.Name}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {bot.Description}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <div className='action-set'>
                                        <div className='btn btn-secondary'
                                            onClick={(e) => handleNavToBot(e, `${getScraperCode(bot.RunApiEndpoint)}`, true)}
                                            ><PestControlIcon />
                                        </div>
                                        <div className='btn btn-danger' onClick={(e) => handleDeleteBot(e, bot.BotId)}><DeleteForeverTwoToneIcon /></div>
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}