import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { useLoaderData } from "react-router-dom";

import Schedule from '../../components/FieldSet/Schedule';

import Api, { getPortals, getBotsByPortalId, getCredsByPortalId } from "@/api/admin-api"
import SaveNewBtn from '@/components/Buttons/SaveNewBtn';
import DestSelect from '@/components/FieldSet/DestinationsSelect';
import EditConfigDetails from '@/components/FieldSet/EditConfigDetails';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Credential, Portal, SnackbarSeverity, Bot, Destination } from '@/constants';

export async function loader() {
    const portals: Array<Portal> = await getPortals();
    const destinations: Array<Destination> = await Api.getDestinations();
    return { portals, destinations };
}  

export default function NewConfigForm() {
    const { portals, destinations }: any = useLoaderData();

    const [bots, setBots] = useState<Array<Bot>>([]);
    const [creds, setCreds] = useState<Array<Credential>>([]);
    const [selectedDest, setSelectedDest] = useState<string>('');

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    const emptyConfig = {ConfigId: 0, BotId: 0, Name: '', Description: '', CronTab: 'None', DestPath: '', CredId: 0, Enabled: true}

    function saveConfig(e: any) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        const formJson = Object.fromEntries(new FormData(e.target).entries());

        if (formJson.isDefault) {
            formJson.isDefault = JSON.stringify(true)
        }
        if (formJson.isEnabled) {
            formJson.enabled = JSON.stringify(true)
        }
        if (!formJson.crontab) {
            formJson.crontab = 'None'
        }
        if (formJson.destpath === '0') {
            handleShowSnackBar(`Destination must be selected.`, SnackbarSeverity.WARNING);
            return
        }
        if (formJson.portalid === '0') {
            handleShowSnackBar(`Portal must be selected.`, SnackbarSeverity.WARNING);
            return
        }
        if (formJson.botid === '0') {
            handleShowSnackBar(`Bot must be selected.`, SnackbarSeverity.WARNING);
            return
        }
        if (formJson.credid === '0') {
            handleShowSnackBar(`Cred must be selected.`, SnackbarSeverity.WARNING);
            return
        }

        Api.saveNewConfig(formJson)
            .then((res: any) => {
                handleShowSnackBar(`Created config "${res.Name}"`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                console.error(error.responseText)
                handleShowSnackBar(`Error creating config: ${error.statusText}`, SnackbarSeverity.ERROR);
        });
    }

    async function handleSelectPortal(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
        setBots(await getBotsByPortalId(e.target.value))
        setCreds(await getCredsByPortalId(e.target.value))
    }
    return (
        <div>
            <h1 className='page-heading'>Create a Bot Config</h1>
            <div>
                <form onSubmit={saveConfig}>
                    <div>
                        <EditConfigDetails config={emptyConfig} isDefault={false} />
                        <DestSelect destinations={destinations} selectedDest={selectedDest} />
                        <fieldset className="form-group border p-3">
                            <legend>Portal Select</legend>
                            <div>
                                {/* <label htmlFor="portals">Choose a portal:</label> */}
                                <select name="portalid" title='portals' onChange={handleSelectPortal}>
                                    <option value={0}>Choose a portal</option>
                                    {
                                        portals.map((portal: Portal, i: number) => {
                                            return <option key={i} value={portal.PortalId}>{portal.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </fieldset>

                        <fieldset className="form-group border p-3">
                            <legend>Bot Select</legend>
                            <div>
                                {/* <label htmlFor="bots">Choose a bot:</label> */}
                                <select name="botid" title='bots'>
                                    <option value={0}>Choose a bot</option>
                                    {
                                        bots.map((bot: Bot, i: number) => {
                                            return <option key={i} value={bot.BotId}>{bot.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </fieldset>

                        <fieldset className="form-group border p-3">
                            <legend>Cred Select</legend>
                            <div>
                                {/* <label htmlFor="creds">Choose a cred:</label> */}
                                <select name="credid" title='creds'>
                                    <option value={0}>Choose a cred</option>
                                    {
                                        creds.map((cred: Credential, i: number) => {
                                            return <option key={i} value={cred.CredId}>{cred.Username}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </fieldset>

                        <Schedule cronTab={'None'} isEdit={true} />

                    </div>
                    <SaveNewBtn text='Save New Config' />
                </form>
            </div>
        </div>
    )
}
