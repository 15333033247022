import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';

import Api, { } from "@/api/admin-api"
import SaveNewBtn from '@/components/Buttons/SaveNewBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { SnackbarSeverity } from '@/constants';
import { validateDownloadDir } from '@/utilities';

export default function NewDestination() {
    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const [path, setDestPath] = useState<string>('');
    const [name, setDestName] = useState<string>('');
    const [desc, setDestDesc] = useState<string>('');

    function handleSubmit(e: any) {
        e.preventDefault();
        const formJson = Object.fromEntries(new FormData(e.target).entries());

        Api.postDestination(formJson)
            .then((res: any) => {
                handleShowSnackBar(`Successfully created the destination.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Failed to create the destination.`, SnackbarSeverity.ERROR);
                console.error(error.responseText)
        });
    }

    return (
        <div>
            <h1 className='page-heading'>Create a Destination</h1>
            <div>
                <div>
                    <form onSubmit={handleSubmit}>
                        <fieldset className="form-group border p-3">
                            <legend>Destination Info</legend>
                            <div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="username" className='text-label'>Path:</label>
                                    <input 
                                        type="text" 
                                        name="destpath" 
                                        id="path"
                                        placeholder='path'
                                        value={path}
                                        onChange={e => setDestPath(e.target.value)}
                                        onBlur={e => setDestPath(validateDownloadDir(e.target.value))}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="name" className='text-label'>Name:</label>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        id="name"
                                        required={true}
                                        placeholder='name'
                                        value={name}
                                        onChange={e => setDestName(e.target.value)}
                                    />
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="name" className='text-label'>Description:</label>
                                    <input 
                                        type="text" 
                                        name="description" 
                                        id="description"
                                        required={true}
                                        placeholder='description'
                                        value={desc}
                                        onChange={e => setDestDesc(e.target.value)}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <SaveNewBtn text='Add Destination' />
                    </form>
                </div>
            </div>
        </div>
    )
}
