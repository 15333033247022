import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

type LoadingBtnProps = {
    isLoading: boolean
    isScrapign: boolean
}

const BootstrapButton = styled(LoadingButton)({
    backgroundImage: 'linear-gradient(to right, #314755 0%, #849da8  51%, #314755 100%)',
    padding: '10px 20px',
    transition: '0.5s',
    backgroundSize: '200% auto',
    color: 'white',
    boxShadow: '0 0 10px #666',
    borderRadius: '10px',
    display: 'block',
    '&:hover': {
        backgroundPosition: 'right center',
        borderColor: '#0062cc',
        boxShadow: 'none',
    }
});

export default function LoadingSubmitBtn(props: LoadingBtnProps) {
    const {
        isLoading
    } = props
    
    return (
        <BootstrapButton loading={isLoading} type='submit' variant="outlined">
            <span className='btn-internal'>
                <LoginIcon />Login (Request OTP)
            </span>
        </BootstrapButton>
    )
}