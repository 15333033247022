import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

type AddBtnProps = {
  lable: string
  handleAdd: any
}

export default function AddBtn(props: AddBtnProps) {
  const {
    lable,
    handleAdd
  } = props

  const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#E8F1F2',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
    
  return (
    <div className='add-btn btn-wrapper'>
      <BootstrapButton onClick={handleAdd} color='info'><span className='btn-icon'><LibraryAddIcon /></span>{lable}</BootstrapButton>
    </div>
  )
}