import { createBrowserRouter } from "react-router-dom";

import Login from './routes/Auth/Login';
import Bot from './routes/Bots/Bot';
import { loader as botLoader } from './routes/Bots/Bot'
import NewBot from './routes/Bots/NewBot';
import { loader as newBotLoader } from './routes/Bots/NewBot'
import RunBots, { loader as runBotsLoader } from './routes/Bots/RunBots'
import Campaign, { loader as campaigLoader } from './routes/Campaign/Campaign'
import CampaignCredentialsEntry from './routes/Campaign/CampaignCredentialsEntry';
import { loader as campaignCredLoader } from './routes/Campaign/CampaignCredentialsEntry'
import { loader as campEntryLoader } from './routes/Campaign/CampaignEntry';
import CampaignEntry from './routes/Campaign/CampaignEntry';
import Campaigns from './routes/Campaign/Campaigns';
import People from './routes/Campaign/People';
import { loader as peopleLoader } from './routes/Campaign/People'
import TokenLogin from './routes/Campaign/TokenLogin';
import { loader as configsLoader } from './routes/Configs/Configs'
import Configs from './routes/Configs/Configs';
import EditConfigForm from './routes/Configs/EditConfigForm';
import {loader as editConfigLoader} from './routes/Configs/EditConfigForm'
import ConfigJobEditor from './routes/Configs/Job/ConfigJobEditor';
import {loader as configJobEditorLoader} from './routes/Configs/Job/ConfigJobEditor'
import EditConfigJob from './routes/Configs/Job/EditConfigJob';
import {loader as editConfigJobLoader} from './routes/Configs/Job/EditConfigJob'
import { loader as newJobLoader } from './routes/Configs/Job/NewConfigJob';
import NewConfigJob from './routes/Configs/Job/NewConfigJob';
import NewConfigForm from './routes/Configs/NewConfigForm';
import { loader as newConfigLoader } from './routes/Configs/NewConfigForm'
import { loader as configLoader } from './routes/Configs/ViewConfig'
import ViewConfig from './routes/Configs/ViewConfig';
import Credential from './routes/Credentials/Credential';
import { loader as credLoader } from './routes/Credentials/Credential'
import Credentials from './routes/Credentials/Credentials';
import { loader as credsLoader } from './routes/Credentials/Credentials'
import NewCredential from './routes/Credentials/NewCred';
import { loader as newCredLoader } from './routes/Credentials/NewCred';
import Destination from './routes/Destinations/Destination';
import { loader as destLoader } from './routes/Destinations/Destination';
import Destinations from './routes/Destinations/Destinations';
import { loader as destsLoader } from './routes/Destinations/Destinations';
import NewDestination from './routes/Destinations/NewDest';
import ErrorPage from "./routes/error-page";
import Help from './routes/Help';
import Portal from './routes/Portals/Portal';
import { loader as portalLoader } from './routes/Portals/Portal'
import Portals from './routes/Portals/Portals';
import { loader as portalsLoader } from './routes/Portals/Portals'

import SnackBarContext from '@/components/Hooks/Notification/SnackBarContext'
import Bots from '@/routes/Bots/Bots';
import { loader as botsLoader } from '@/routes/Bots/Bots'
import BotsOverview from '@/routes/Bots/Overview';
import { loader as overViewLoader } from '@/routes/Bots/Overview'
import { loader as campsLoader } from '@/routes/Campaign/Campaigns'
import Home from '@/routes/Home';
import Menu from '@/routes/Menu';

import './style/main.scss';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Menu />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <SnackBarContext><Home /></SnackBarContext>,
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/help",
                element: <Help />,
            },
            {
                path: "/overview",
                element: <SnackBarContext><BotsOverview /></SnackBarContext>,
                loader: overViewLoader,
            },
            {
                path: "bots/:botId/configs/:configId/debug",
                element: <SnackBarContext><RunBots /></SnackBarContext>,
                loader: runBotsLoader,
            },
            {
                path: "bots",
                element: <SnackBarContext><Bots /></SnackBarContext>,
                loader: botsLoader,
            },
            {
                path: "bots/new",
                element: <SnackBarContext><NewBot /></SnackBarContext>,
                loader: newBotLoader,
            },
            {
                path: "bots/:botId",
                element: <SnackBarContext><Bot /></SnackBarContext>,
                loader: botLoader,
            },
            // bot configs
            {
                path: "bots/:botId/configs",
                element: <SnackBarContext><Configs /></SnackBarContext>,
                loader: botLoader,
            },
            {
                path: "configs/:configId",
                element: <SnackBarContext><ViewConfig /></SnackBarContext>,
                loader: configLoader,
            },
            {
                path: "configs/:configId/edit",
                element: <SnackBarContext><EditConfigForm /></SnackBarContext>,
                loader: editConfigLoader,
            },
            // bot/config/job
            {
                path: "configs/:configId/job",
                element: <SnackBarContext><EditConfigJob /></SnackBarContext>,
                loader: editConfigJobLoader,
            },
            {
                path: "configs/:configId/job/edit",
                element: <SnackBarContext><ConfigJobEditor /></SnackBarContext>,
                loader: configJobEditorLoader,
            },
            {
                path: "configs/:configId/job/new",
                element: <SnackBarContext><NewConfigJob /></SnackBarContext>,
                loader: newJobLoader,
            },
            // configs
            {
                path: "configs",
                element: <SnackBarContext><Configs /></SnackBarContext>,
                loader: configsLoader,
            },
            {
                path: "configs/new",
                element:<SnackBarContext><NewConfigForm /></SnackBarContext>,
                loader: newConfigLoader,
            },
            // creds pages
            {
                path: "credentials",
                element: <SnackBarContext><Credentials /></SnackBarContext>,
                loader: credsLoader,
            },
            {
                path: "credentials/new",
                element: <SnackBarContext><NewCredential /></SnackBarContext>,
                loader: newCredLoader,
            },
            {
                path: "credentials/:credId",
                element: <SnackBarContext><Credential /></SnackBarContext>,
                loader: credLoader,
            },
            // destinations
            {
                path: "destinations",
                element: <SnackBarContext><Destinations /></SnackBarContext>,
                loader: destsLoader,
            },
            {
                path: "destinations/:path",
                element: <SnackBarContext><Destination /></SnackBarContext>,
                loader: destLoader,
            },
            {
                path: "destinations/new",
                element: <SnackBarContext><NewDestination /></SnackBarContext>,
            }, 
            // portals
            {
                path: "portals",
                element: <SnackBarContext><Portals /></SnackBarContext>,
                loader: portalsLoader,
            },   
            {
                path: "portals/:portalId",
                element: <SnackBarContext><Portal /></SnackBarContext>,
                loader: portalLoader,
            },   
            // people
            {
                path: "/people",
                element: <SnackBarContext><People /></SnackBarContext>,
                loader: peopleLoader,
                errorElement: <ErrorPage />,
            },
            // campaigns
            {
                path: "campaigns",
                element: <SnackBarContext><Campaigns /></SnackBarContext>,
                loader: campsLoader,
            },
            {
                path: "campaigns/:campaignId",
                element: <SnackBarContext><Campaign /></SnackBarContext>,
                loader: campaigLoader,
            },  
        ]
    },
    // campaign pages
    {
        path: "tokenlogin",
        element: <TokenLogin />
    },
    {
        path: "entry/campaigns",
        element: <SnackBarContext><CampaignEntry /></SnackBarContext>,
        loader: campEntryLoader,
        errorElement: <ErrorPage />,
    },
    {
        path: "entry/campaigns/:campaignId",
        element: <SnackBarContext><Campaign /></SnackBarContext>,
        loader: campaigLoader,
        errorElement: <ErrorPage />,
    },
    {
        path: "entry/campaigns/:campaignId/credentials",
        element: <SnackBarContext><CampaignCredentialsEntry /></SnackBarContext>,
        loader: campaignCredLoader,
        errorElement: <ErrorPage />,
    },
]);

export default router;
