import { Destination } from "@/constants";

type Props = {
    destinations: Array<Destination>,
    selectedDest: string
};

export default function DestSelect(props: Props) {

    const {
        destinations,
        selectedDest
    } = props;

    return (
        <fieldset className="form-group border p-3">
            <legend>Destination Select</legend>
            <div>
                <select name="destpath" title='dests' required={true} defaultValue={selectedDest}>
                    <option value={0}>Choose a destination</option>
                    {
                        destinations.map((dest: Destination, i: number) => {
                            return <option key={i} value={dest.DestPath}>{dest.DestPath}</option>
                        })
                    }
                </select>
            </div>
        </fieldset>
    )
}