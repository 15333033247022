import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForever from '@mui/icons-material/DeleteForever'
import PestControl from '@mui/icons-material/PestControl';
import { AlertColor } from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";

import { getConfigs, deleteConfig, getBotByConfigId, getAllJobs } from '@/api/admin-api';
import AddBtn from '@/components/Buttons/AddBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Bot, BotConfig, Job, SnackbarSeverity } from '@/constants';
import { getScraperCode } from '@/utilities';

import '@/components/Buttons/buttons.scss'

export async function loader() {
    const configs: Array<BotConfig> = await getConfigs();
    const jobs: Array<Job> = await getAllJobs();
    return { configs, jobs };
}

export default function Configs() {
    const { configs, jobs }: any = useLoaderData();
    const showSnack = useSnackbar();

    const [configsState, setConfigsState] = useState<Array<BotConfig>>(configs);

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const navigate = useNavigate();

    function handleDeleteConfig(event: React.MouseEvent<HTMLElement>, configId: number) {
        event.stopPropagation();
        if (window.confirm(`Delete the config?`)) {
            deleteConfig(configId).then(() => {
                setConfigsState(configsState.filter(a => a.ConfigId !== configId));
                handleShowSnackBar(`Deleted the config.`, SnackbarSeverity.SUCCESS)
            }).catch(() => {
                handleShowSnackBar(`Error deleting the config.`, SnackbarSeverity.ERROR)
            })
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));

    async function handleNavigateToConfig(event: React.MouseEvent<HTMLElement>, configId: number, edit: boolean) {
        event.stopPropagation();
        const botcode: Bot = await getBotByConfigId(configId);
        const scraperCode = getScraperCode(botcode.RunApiEndpoint);
        if (edit)
            navigate(`/configs/${configId}`)
        else
            navigate(`/bots/${scraperCode}/configs/${configId}/debug`)
    }

    async function handleNavigateToConfigJob(event: React.MouseEvent<HTMLElement>, configId: number) {
        event.stopPropagation();
        navigate(`/configs/${configId}/job`)
    }

    return (
        <div className='route-container'>
            <div className='page-heading'>
                <h1>Bot Configs</h1>
                <AddBtn lable='Add a Config' handleAdd={() => navigate('/configs/new')} />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell align="right">Name</StyledTableCell>
                            <StyledTableCell align="right">Description</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {configsState.map((config: BotConfig) => {
                            let configJob: Job | undefined;
                            if (jobs)
                                configJob = jobs.find((job: Job) => job.ConfigId === config.ConfigId);
                            return (<TableRow
                                hover
                                key={config.ConfigId}
                                sx={{
                                    '*': { cursor: 'pointer' }
                                }}
                                onClick={(e) => handleNavigateToConfig(e, config.ConfigId, true)}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {config.Enabled ? <span className="badge badge-success">Enabled</span> : <span className="badge badge-danger">Disabled</span>}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {config.Name}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {config.Description}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <div className='action-set'>
                                        {configJob && <Tooltip title='config job'>
                                            <div className='btn btn-general' onClick={(e) => handleNavigateToConfigJob(e, config.ConfigId)}>
                                                <FontAwesomeIcon icon={faClipboardCheck} />
                                            </div>
                                        </Tooltip>}
                                        <Tooltip title='debug config'>
                                            <div className='btn btn-secondary'
                                                onClick={(e) => handleNavigateToConfig(e, config.ConfigId, false)}
                                            >
                                                <PestControl />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='delete config'><div className='btn btn-danger' onClick={(e: React.MouseEvent<HTMLElement>) => handleDeleteConfig(e, config.ConfigId)}><DeleteForever /></div></Tooltip>
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
