import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridActionsCellItem,
    GridRowId,
    GridRowSelectionModel,
    GridRenderCellParams,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from '@mui/x-data-grid';
import * as React from 'react';

import ClientsSelect from '@/components/Misc/ClientsMultiSelect';
import { Job, POLVdsIds, PolScrapeJob } from '@/constants';

import '@/components/component-style.scss'

function CustomToolbar() {
    return (
        <GridToolbarContainer className='mb-1'>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector
                slotProps={{ tooltip: { title: 'Change density' } }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarExport
                slotProps={{
                    tooltip: { title: 'Export data' },
                    button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    );
  }

type JobTableProps = {
    loading: boolean,
    polvds: Array<POLVdsIds>,
    jobstate: Job,
    setjob: any,
    clients: Array<string>,
}

export default function DataTable(props: JobTableProps) {
    const {
        loading,
        polvds,
        jobstate,
        setjob,
        clients,
    } = props;

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [polState, setPolState] = React.useState<Array<PolScrapeJob>>([]);

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    React.useEffect(() => {
        loadCheckBoxArray();
    }, [polvds.length, jobstate.JobString])

    // TODO: handle save
    // const handleSaveClick = (id: GridRowId) => () => {
    //     setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //     savechanges(jobstate)
    // };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = polvds.find((row) => row.Id === id);
        // TODO: reset on cancel
    };

    const columns: GridColDef[] = [
        { field: 'Id', headerName: 'Vds Id', width: 120 },
        { field: 'Name', headerName: 'Vendor Name', width: 250 },
        {
            field: 'clientSelect',
            headerName: 'Selected Clients',
            width: 320,
            editable: true,
            type: 'singleSelect',
            valueOptions: clients,
            valueGetter: (value, row) => {
                try {
                    const selectedClients = JSON.parse(jobstate.JobString)[row.Id.toString()]
                    if (selectedClients)
                        return selectedClients.join(',')
                } catch (err: any) {
                    return;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                try {
                    const selectedClients = JSON.parse(jobstate.JobString)[params.id.toString()]
                    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                    if (selectedClients)
                        return <ClientsSelect disabled={!isInEditMode} options={clients} onChange={(e: React.ChangeEvent<HTMLInputElement>, clients: Array<string>)=>handleUpdateClient(params.id.toString(), clients)} selectedClients={selectedClients} />
                } catch (err: any) {
                    return;
                }
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                try {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                    const selectedClients = JSON.parse(jobstate.JobString)[id.toString()]
                    if (isInEditMode && selectedClients) {
                        return [
                            // <GridActionsCellItem
                            //     icon={<SaveIcon />}
                            //     label="Save"
                            //     sx={{
                            //     color: 'primary.main',
                            //     }}
                            //     onClick={handleSaveClick(id)}
                            //     key={id}
                            // />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                                key={id}
                            />,
                        ];
                    }
                    return [
                        <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        key={id}
                        disabled={!selectedClients}
                        />
                    ];
                } catch (err: any) {
                    return [
                        <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        key={id}
                        disabled={true}
                        />
                    ];
                }
            }
        }
      ];

    function loadCheckBoxArray() {
        const results: GridRowSelectionModel = [];
        for (const i in polvds) {
            try {
                const isarr = JSON.parse(jobstate.JobString).constructor == Array;
                let checked = Object.keys(JSON.parse(jobstate.JobString)).includes(polvds[i].Id?.toString());
                if (isarr) {
                    checked = JSON.parse(jobstate.JobString).includes(polvds[i].Id?.toString());
                }
                if (checked) {
                    const vdsId = polvds[i] as any;
                    setPolState([...polState, vdsId])
                    results.push(polvds[i].Id)
                }
            } catch (err: any) {
                console.error(err.message)
                break;
            }
        }
        setRowSelectionModel(results)
    }

    function handleUpdateClient(vdsId: string, clients: Array<string>) {
        try {
            const newJobStr = JSON.parse(jobstate.JobString.replace(/'/g, '"'))
            Object.assign(newJobStr, {[vdsId]: clients});
            console.log(newJobStr)
            setjob({...jobstate, JobString: JSON.stringify(newJobStr)})
        } catch (err: any) {
            console.warn(err.message)
        }
    }

    return (
        <div className='datatable'>
            <DataGrid
                onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                    let changedId: GridRowId[] = []
                    try {
                        const newJobStr = JSON.parse(jobstate.JobString.replace(/'/g, '"'))
                        if (newRowSelectionModel.length === 0) {
                            setjob({...jobstate, JobString: JSON.stringify({})});
                            setRowSelectionModel(newRowSelectionModel);
                            return;
                        }
                        else if (newRowSelectionModel.length > rowSelectionModel.length) {
                            changedId = newRowSelectionModel.filter((element: GridRowId) => !rowSelectionModel.includes(element));
                        } else {
                            changedId = rowSelectionModel.filter((element: GridRowId) => !newRowSelectionModel.includes(element));
                            delete newJobStr[changedId.toString()]
                            setjob({...jobstate, JobString: JSON.stringify(newJobStr)})
                            setRowSelectionModel(newRowSelectionModel);
                            return
                        }
                        if (changedId.length > 1) {
                            changedId.forEach(item => {
                                Object.assign(newJobStr, {[item.toString()]: [clients[0]]});
                            })
                        } else {
                            Object.assign(newJobStr, {[changedId[0].toString()]: [clients[0]]});
                        }
                        setjob({...jobstate, JobString: JSON.stringify(newJobStr)})
                        // TODO: compare job string to rows to get not found vds ids
                        setRowSelectionModel(newRowSelectionModel);
                    } catch (err: any) {
                        return;
                    }
                }}
                // loading={polvds.length === 0}
                loading={loading}
                rowSelectionModel={rowSelectionModel}
                getRowId={row=>row.Id}
                rows={polvds}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}