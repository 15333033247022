import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { useLoaderData } from "react-router-dom";

import Api, { } from "@/api/admin-api"
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import { Destination, SnackbarSeverity } from '@/constants';
import { hexDecode } from '@/utilities';

export async function loader({ params }: any) {
    const dest = await Api.getDestination(hexDecode(params.path))
    return { dest };
}

export default function DestinationForm() {
    const { dest }: any = useLoaderData();

    const showSnack = useSnackbar();
    const handleShowSnackBar = (message: string, severity: AlertColor) => {
        showSnack(message, severity);
    };

    const [name, setDestName] = useState<string>(dest.Name);
    const [desc, setDestDesc] = useState<string>(dest.Description);

    const [putDest, setPutDest] = useState<boolean>(false);

    function handleDestUpdate(e: any) {
        e.preventDefault();
        const formJson = Object.fromEntries(new FormData(e.target).entries());
        formJson.destPath = dest.DestPath
        Api.putBotDest(dest.DestPath, formJson)
            .then((res: any) => {
                handleShowSnackBar(`Successfully updated the destination.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Failed to update the destination.`, SnackbarSeverity.ERROR);
                console.error(error.responseText)
        });
    }

    function handleDeleteDest(path: string) {
        if (window.confirm(`Are you sure you'd like to delete the "${path}" destination?`)) {
            Api.deleteDest(path).then(() => {
                handleShowSnackBar(`Successfully deleted the destination.`, SnackbarSeverity.SUCCESS);
            }).catch((err: any) => {
                handleShowSnackBar(`Delete fail: ${err.responseJSON.Message}.`, SnackbarSeverity.ERROR);
            })
        }
    }

    return (
        <div>
            <h1 className='page-heading'>Destination View/Edit Page</h1>
            <div>
                <div>
                    <form onSubmit={handleDestUpdate}>
                        <fieldset className="form-group border p-3">
                            <legend>Destination Info</legend>
                            <div>
                                <div className='text-input-label-group'>
                                    <label className='text-label' htmlFor="destpath">Path:</label>
                                    <input
                                        type='text'
                                        name='destpath'
                                        id='destpath'
                                        value={dest.DestPath}
                                        disabled={true}
                                    />
                                    {putDest && <small>Path value cannot be changed once created. Must create a new destination.</small>}
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="name" className='text-label'>Name:</label>
                                    {putDest ?
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name"
                                            required={true}
                                            value={name}
                                            onChange={e => setDestName(e.target.value)}
                                        />
                                    :   <div>
                                            {name}
                                        </div>
                                    }
                                </div>
                                <div className='text-input-label-group'>
                                    <label htmlFor="description" className='text-label'>Description:</label>
                                    {putDest ?
                                        <input 
                                            type="text" 
                                            name="description" 
                                            id="description"
                                            required={true}
                                            value={desc}
                                            onChange={e => setDestDesc(e.target.value)}
                                        />
                                    :   <div>
                                            {desc}
                                        </div>
                                    }
                                </div>
                            </div>
                        </fieldset>
                        <button type="button" className='btn btn-secondary' onClick={() => setPutDest(!putDest)}>{putDest ? '<- View' : 'Edit'}</button>
                        {!putDest ? <button type="button" className='btn btn-danger' onClick={e => {
                                e.preventDefault()
                                handleDeleteDest(dest.DestPath)
                        }}>Delete</button>: <></>}
                        {putDest ? <button type="submit" className='btn btn-primary'>Submit</button> : <></>}
                    </form>
                </div>
            </div>
        </div>
    )
}
