import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';
import { useLoaderData } from "react-router-dom";

import { getConfig, postJob } from "@/api/admin-api"
import SaveNewBtn from '@/components/Buttons/SaveNewBtn';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeJob from "@/components/ScraperForm/ScrapeJob";
import { SnackbarSeverity, Job, BotConfig } from '@/constants';

export async function loader({ params }: any) {
    const config: BotConfig = await getConfig(params.configId);
    return { config };
}

export default function NewConfigJob() {
    const { config }: any = useLoaderData();

    const [job, setJob] = useState<Job>({ConfigId: config.ConfigId, Name: '', Description: '', JobString: '', ModifiedBy: '', ModifiedDate: '', ModifiedReason: '', CreateDate: ''})

    const showSnack = useSnackbar();

    const handleShowSnackBar = (message: string, severity: AlertColor) => {
      showSnack(message, severity);
    };

    function handleAddJob(e: any) {
        e.preventDefault();

        const formJson = Object.fromEntries(new FormData(e.target).entries());
        formJson.configId = config.ConfigId

        postJob(config.ConfigId, formJson)
            .then((res: any) => {
                handleShowSnackBar(`Successfully added the config job: ${res.Name}.`, SnackbarSeverity.SUCCESS);
            }).catch((error: any) => {
                handleShowSnackBar(`Error adding the config job: ${error.responseText}.`, SnackbarSeverity.ERROR);
                console.error(error.responseText)
        });
    }

    return (
        <div>
            <h1 className='page-heading'>Add job to {config.Name}</h1>
            <form onSubmit={handleAddJob}>
                <fieldset className="form-group border p-3">
                    <legend>Job Info</legend>
                        <div>
                            <div className='text-input-label-group'>
                                <label htmlFor="jobname" className='text-label'>Name:</label>
                                <input
                                    type="text"
                                    id="jobname"
                                    name='name'
                                    placeholder='Job name'
                                    autoFocus
                                    required={true}
                                    onChange={e => job.Name = e.target.value}
                                />
                            </div>
                            <div className='text-input-label-group'>
                                <label htmlFor="jobdescription" className='text-label'>Description:</label>
                                <input
                                    type="textarea"
                                    id="jobdescription"
                                    name='description'
                                    placeholder='Job description'
                                    required={true}
                                    onChange={e => job. Description = e.target.value}
                                />
                            </div>
                        </div>
                    <ScrapeJob disabled={false} job={job} setJob={setJob} placeHolder='n/a' />
                </fieldset>
                <SaveNewBtn text={`Add the job to [${config.Name}]`} />
            </form>
        </div>
    )
}
