import HttpIcon from '@mui/icons-material/Http';
import PasswordIcon from '@mui/icons-material/Password';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Link } from "react-router-dom"

export default function Help() {
    return (
        <div>
            <h1 className='page-heading'>SetSight Scraping App</h1>
            <h2 className="home-subheading">Steps to setup bots and configs</h2>
            <ol>
                <li>Setup a <Link to={'/portals'} className='link-btn'><button type='button'>Portal <HttpIcon color='info'/></button></Link> (<em>web domain</em>)</li>
                <li>Setup <Link to={'/credentials'} className='link-btn'><button type='button'>Credentials <PasswordIcon color='info'/></button></Link> (<em>for the portal</em>)</li>
                <li>Setup a <Link to={'/bots'} className='link-btn'><button type='button'>Bot <SmartToyIcon color='info'/></button></Link> (<em>base scraper</em>)</li>
                <li>Setup a <Link to={'/destinations'} className='link-btn'><button type='button'>Destination <PinDropIcon color='info'/></button></Link> (<em>server path to send data</em>)</li>
                <li>Setup the bot <Link to={'/configs'} className='link-btn'><button type='button'>Configs <SettingsIcon color='info'/></button></Link> (<em>which will depend on the artificats previously configured</em>)</li>
            </ol>
        </div>
    )
}